import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Col, Form, Radio, Result, Row, Select, Skeleton, Tag, message } from "antd";
import React from "react";

import i18next from "i18next";
import { Link } from "react-router-dom";
import { NJVButton, NJVCard, NJVInput, NJVRadio, NJVRadioGroup, NJVSelect } from "../../components/core-component";
import Theme from "../../components/theme";
import { NJVSearchFilterOption, PhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { Option } = Select

class UserAddressUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            division_collection: [],
            township_collection: [],
            is_my_location: false,
            is_default_location: false,
            isDataFetching: false,
            isTownShipFetching: false,
            isNotFound: false,
            is_updating_data: false,
        }
        this.inputRef = React.createRef();

    }

    componentDidMount() {
        this.fetchDivision()
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            const response = await ApiHandler({ url: Api.user_address, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            if (response) {
                const townshipId = response.township.id
                const divisionId = response.township.division.id
                var userAddress = { ...response, 'division': divisionId, 'township': townshipId }
                this.fetchTownship(divisionId).then(() => {
                    this.setState({
                        is_my_location: response.myLocation,
                        data: userAddress,
                        isDataFetching: false
                    }, () => {
                        if (this.inputRef.current && this.state.data) {
                            this.inputRef.current.setFieldsValue(this.state.data)
                        }
                    });
                })

            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.code) {
                let code = error.response.data.code
                if (code === '041') {
                    this.setState({
                        isNotFound: true
                    })
                }
            }
            this.setState({
                isDataFetching: false
            })
        }

    }

    // divisionSelectorChange = (value) => {
    //     this.fetchTownship(value)
    //     this.inputRef.current.setFieldsValue({ township: null })
    // }

    fetchDivision = async () => {
        try {
            const response = await ApiHandler({ url: Api.division_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            // if (response && response.content) {
            //     this.setState({
            //         division_collection: response.content,
            //         totalPagination: response.totalElements,
            //         page: 0,
            //         division_first_id: response.content[0].name
            //     });

            // }
            // this.fetchTownship(response.content[0].id)
            await Promise.all(
                response.content.map(division => this.fetchTownship(division.id))
            );
        } catch (error) {
        }
    }

    fetchTownship = async (id) => {
        this.setState({
            isTownShipFetching: true
        })
        try {
            var requestParams = {
                page: 0,
                size: 100,
                name: '',
                divisionId: id
            }
            const response = await ApiHandler({ url: Api.township_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })

            if (response && response.content) {
                this.setState((prevState) => ({
                    township_collection: [
                        ...new Map([...prevState.township_collection, ...response.content]
                            .map(item => [item.id, item])
                        ).values()
                    ],
                    totalPagination: response.totalElements,
                    page: 0,
                }));

            }
        } catch (error) {
        }
        this.setState({
            isTownShipFetching: false
        })
    }

    setDefaultAddress = (event) => {
        this.setState({
            is_default_location: event.target.checked
        })

    }
    setMyAddress = (event) => {
        const locationTypeValue = parseInt(event.target.value);

        this.setState({
            is_my_location: locationTypeValue === 1 ? false : true,
            is_default_location: event.target.checked ? this.state.is_default_location : false,
        })
    }

    updateUserAddress = async (value) => {
        const { is_my_location, data } = this.state
        this.setState({
            is_updating_data: true
        })
        try {
            const response = await ApiHandler({
                url: Api.user_address, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "id": data.id,
                    "name": value.name,
                    "phone": value.phone,
                    "address1": value.address1,
                    "township": {
                        "id": value.township
                    },
                    "defaultLocation": data.defaultLocation,
                    "myLocation": is_my_location,
                    "latitude": "12.343434",
                    "longitude": "13.938444"
                }
            }
            )
            if (response.status === 200) {
                this.props.navigate(this.props.location.state.prevRoute ? this.props.location.state.prevRoute : CustomPath.user_address, { state: this.props.location.state })
            }
        } catch (error) {
            if (error && error.response.data.code === "044") {
                message.error(error.response.data.message)
                this.setState({
                    isCreatingParcel: false
                });
            }
        }
        this.setState({
            is_updating_data: false
        })
    }

    render() {
        const { township_collection, is_my_location, isDataFetching, isTownShipFetching, isNotFound, is_updating_data, data } = this.state

        return (
            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumb
                        items={[
                            {
                                title: 'Home',
                            },
                            {
                                title: <Link to={CustomPath.user_address}>{i18next.t('fav_location')}</Link>,
                            }
                        ]}
                    />
                    <br />
                </Col>
                <Col span={24}>
                    {
                        isDataFetching ?
                            <Skeleton active />
                            :
                            <>
                                {
                                    isNotFound ?
                                        <Result
                                            status="404"
                                            title="404"
                                            subTitle="Sorry, the page you visited does not exist."
                                            extra={<Link to={this.props.location.state.prevRoute ? this.props.location.state.prevRoute : CustomPath.user_address}><NJVButton type="primary" buttonprimary={Theme.colors.primary}>{i18next.t('back')}</NJVButton></Link>}
                                        />
                                        :
                                        <NJVCard
                                            title="Update Form"
                                            style={{ paddingTop: 15 }}
                                            extra={
                                                <Link to={this.props.location.state.prevRoute ? this.props.location.state.prevRoute : CustomPath.user_address}><NJVButton type="primary" buttonprimary={Theme.colors.primary}>{i18next.t('back')}</NJVButton></Link>
                                            }
                                        >
                                            <Form
                                                layout="vertical"
                                                ref={this.inputRef}
                                                onFinish={this.updateUserAddress}
                                            >
                                                <Row gutter={[8, 8]}>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item className="location" name="name" label={i18next.t("name")} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVInput size="large" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item className="location" name="phone" label={i18next.t("phone_number")} size={'large'} rules={[{ validator: PhoneNumberValidator }, { required: true }]}>
                                                            <NJVInput size="large" placeholder={"0979xxxxxxx"} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item name="township" label={i18next.t("township")} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVSelect
                                                                className="my-location-selector"
                                                                placeholder={i18next.t("choose_township")}
                                                                showSearch
                                                                allowClear
                                                                filterOption={NJVSearchFilterOption}
                                                                size="large"
                                                                loading={isTownShipFetching}
                                                            >
                                                                {
                                                                    township_collection && township_collection.map((township) => {
                                                                        return (
                                                                            <Option
                                                                                key={township?.id}
                                                                                value={township?.id}
                                                                                label={`${township?.name}, ${township?.division?.name}`}
                                                                            >
                                                                                {township?.name}, &nbsp;
                                                                                <span style={{ color: Theme.colors.primary }}>
                                                                                    {township?.division?.name}
                                                                                </span> &nbsp;
                                                                                <Tag color="green">
                                                                                    {township?.postalCode}
                                                                                </Tag>
                                                                            </Option>
                                                                        )
                                                                    })
                                                                }
                                                            </NJVSelect>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item className="location" name="address1" label={i18next.t("address")} rules={[{ required: true, message: '*(requried)' }]}>
                                                            <NJVInput
                                                                style={{ height: 60 }}
                                                                showCount
                                                                maxLength={180}
                                                                placeholder={i18next.t("enter_address")} istextareainput="true" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item label={i18next.t("location_type")}>
                                                            <NJVRadioGroup disabled={data.defaultLocation} onChange={this.setMyAddress} value={is_my_location ? 2 : 1}>
                                                                <Radio value={1}>{i18next.t("loyal_customer")}</Radio>
                                                                <Radio value={2}>{i18next.t("my_address")}</Radio>
                                                            </NJVRadioGroup>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12} md={12} sm={24} xs={24}>
                                                        <Form.Item>
                                                            <NJVButton disabled={data.defaultLocation} buttonprimary={Theme.colors.primary} style={{ width: 200 }} type="primary" htmlType="submit" loading={is_updating_data}>{i18next.t("update")}</NJVButton>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </NJVCard>
                                }
                            </>
                    }
                </Col>
            </Row>
        )
    }
}
export default compose(withRouter)(UserAddressUpdate); 