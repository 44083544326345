import { message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import i18next from 'i18next';
import cookies from "js-cookie";
import { RefreshToken } from '../auth/auth-provider';
import { LOC_clear_token, LOC_getAccessToken, LOC_storeToken } from '../core/custom-local-storage';
import Api from './api';
import { HTTP_METHOD } from './httpMethod';
import { MEDIA_TYPE } from './mediaType';

const controllers = new Map();

const axiosInstance = axios.create({
    baseURL: Api.host,
    timeout: 120000
});

const axiosInstanceNoAuth = axios.create({
    baseURL: Api.host,
    timeout: 120000
});

axiosInstanceNoAuth.interceptors.request.use(
    async config => {
        const currentLanCode = cookies.get("i18next") || "en"
        config.headers = {
            'Accept-Language': currentLanCode
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.request.use(
    async config => {
        const token = LOC_getAccessToken()
        const currentLanCode = cookies.get("i18next") || "en"
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept-Language': currentLanCode
        }
        const controller = new AbortController();
        config.signal = controller.signal;
        controllers.set(config.url, controller);
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(
    (response) => {
        controllers.delete(response.config.url);
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            try {
                const responseData = await RefreshToken();
                LOC_storeToken(responseData)
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`;
                return axiosInstance(error.config);
            } catch (refreshError) {
                message.destroy()
                message.error("Login expired! Please login again!")
                LOC_clear_token();
                window.location.href = '/'
            }
        } else if (error.response.status === 500) {
            if (error.response && error.response.data && error.response.data.code) {
                let code = error.response.data.code
                if (code === '045') {
                    message.error(i18next.t("user_address_duplicate"))
                } else {
                    throw error
                }
            }
        }
        controllers.delete(error.config?.url);
        return Promise.reject(error);
    }
);

export const cancelRequest = (url) => {
    const controller = controllers.get(url);
    if (controller) controller.abort();
};

export const ApiInstance = async (url, method, mediaType, requestData, specificId, requestParams, customMessage, showMessage) => {
    try {
        let responseData = null
        let response = null
        url = specificId ? url + `/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
            }
        }
        if (method === HTTP_METHOD.GET) {
            if (requestData) {
                response = await axiosInstance.get(url, { params: requestData })
            } else {
                response = await axiosInstance.get(url)
            }
            responseData = response.data
        }
        else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.FORM_DATA) {
            responseData = await axiosInstance.post(url, requestData, header);
        } else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.JSON) {
            responseData = await axiosInstance.post(url, requestData);
        } else if (method === HTTP_METHOD.POST) {
            responseData = await axiosInstance.post(url, requestData, { params: requestParams })
        }
        else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.FORM_DATA) {
            responseData = await axiosInstance.put(url, requestData, header);
        } else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.JSON) {
            responseData = await axiosInstance.put(url, requestData);
        } else if (method === HTTP_METHOD.DELETE) {
            responseData = await axiosInstance.delete(url);
        }

        if (!customMessage) {
            if (showMessage) {

            } else {
                if (method === HTTP_METHOD.POST) {
                    customMessage = "Successfully Created"
                    message.success(customMessage)
                } else if (method === HTTP_METHOD.PUT) {
                    customMessage = "Successfully Updated"
                    message.success(customMessage)
                } else if (method === HTTP_METHOD.DELETE) {
                    customMessage = "Successfully Deleted"
                    message.success(customMessage)
                }
            }
        } else {
            message.destroy()
            message.success(customMessage)
        }
        return responseData
    } catch (error) {

        throw error;
    }
}

// export const ApiInstanceNoAuth = async (url, method, mediaType, requestData, specificId, requestParams, customMessage) => {
//     try {
//         let responseData = null
//         let response = null
//         url = specificId ? url + `/${specificId}` : url
//         const header = {
//             headers: {
//                 'Content-Type': mediaType,
//             }
//         }
//         if (method === HTTP_METHOD.GET) {
//             if (requestData) {
//                 response = await axiosInstanceNoAuth.get(url, { params: requestData })
//             } else {
//                 response = await axiosInstanceNoAuth.get(url)
//             }
//             responseData = response.data
//         } else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.FORM_DATA) {
//             responseData = await axiosInstanceNoAuth.post(url, requestData, header);
//         } else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.JSON) {
//             responseData = await axiosInstanceNoAuth.post(url, requestData, { params: requestParams });
//         } else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.FORM_DATA) {
//             responseData = await axiosInstanceNoAuth.put(url, requestData, header);
//         } else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.JSON) {
//             responseData = await axiosInstanceNoAuth.put(url, requestData);
//         } else if (method === HTTP_METHOD.DELETE) {
//             responseData = await axiosInstanceNoAuth.delete(url);
//         }
//         if (!customMessage) {
//             if (method === HTTP_METHOD.POST) {
//                 customMessage = "Successfully Created"
//                 message.success(customMessage)
//             } else if (method === HTTP_METHOD.PUT) {
//                 customMessage = "Successfully Updated"
//                 message.success(customMessage)
//             } else if (method === HTTP_METHOD.DELETE) {
//                 customMessage = "Successfully Deleted"
//                 message.success(customMessage)
//             }
//         } else {
//             message.destroy()
//             message.success(customMessage)
//         }
//         return responseData
//     } catch (error) {

//         throw error;
//     }
// }

export const ApiHandler = async ({ url, method, mediaType, requestData, specificId, requestParams, customMessage, disableShowMessage }) => {

    try {
        let responseData = null
        let response = null
        const finalUrl = specificId ? `${url}/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
                'source': 'shipper-portal'
            }
        }

        switch (method) {
            case HTTP_METHOD.GET:
                response = await axiosInstance.get(finalUrl, { params: requestParams });
                responseData = response.data;
                break;
            case HTTP_METHOD.POST:
                responseData = await axiosInstance.post(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.PUT:
                responseData = await axiosInstance.put(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.DELETE:
                responseData = await axiosInstance.delete(finalUrl);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }

        message.destroy();

        if (!disableShowMessage) {
            if (customMessage) {
                message.success(customMessage);
            } else {
                switch (method) {
                    case HTTP_METHOD.POST:
                        message.success("Successfully Created");
                        break;
                    case HTTP_METHOD.PUT:
                        message.success("Successfully Updated");
                        break;
                    case HTTP_METHOD.DELETE:
                        message.success("Successfully Deleted");
                        break;
                    default:
                        break;
                }
            }
        }


        return responseData;
    } catch (error) {
        throw error;
    }
}

export const ApiHandlerNoAuth = async ({ url, method, mediaType, requestData, specificId, requestParams, customMessage, disableShowMessage }) => {

    try {
        let responseData = null
        let response = null
        const finalUrl = specificId ? `${url}/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
            }
        }

        switch (method) {
            case HTTP_METHOD.GET:
                response = await axiosInstanceNoAuth.get(finalUrl, { params: requestParams });
                responseData = response.data;
                break;
            case HTTP_METHOD.POST:
                responseData = await axiosInstanceNoAuth.post(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.PUT:
                responseData = await axiosInstanceNoAuth.put(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.DELETE:
                responseData = await axiosInstanceNoAuth.delete(finalUrl);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }

        message.destroy();

        if (!disableShowMessage) {
            if (customMessage) {
                message.success(customMessage);
            } else {
                switch (method) {
                    case HTTP_METHOD.POST:
                        message.success("Successfully Created");
                        break;
                    case HTTP_METHOD.PUT:
                        message.success("Successfully Updated");
                        break;
                    case HTTP_METHOD.DELETE:
                        message.success("Successfully Deleted");
                        break;
                    default:
                        break;
                }
            }
        }
        return responseData;
    } catch (error) {
        throw error;
    }
}

export const DownloadOrderFormatExcel = async () => {
    let responseData = null
    try {
        await axiosInstance.get(Api.download_order_sample_excel, {
            responseType: 'blob',
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Ninja Van Bulk Order Create Template.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            responseData = response
        }).catch(error => {
            responseData = error
        });
    } catch (error) {
    }
    return responseData
}

export const downloadExcel = async (url, params, excelName) => {
    let responseData = null
    let name = excelName ? excelName + ".xlsx" : 'order_excel_template_format.xlsx'
    try {
        await axiosInstance.get(url,
            {
                params: params,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                responseData = response
            }).catch(error => {
                responseData = error

            });
    } catch (error) {
    }
    return responseData
}

const axiosInstanceInvoiceServer = axios.create({
    baseURL: Api.invoice_host
});

export const InvoiceApiPost = async (url, requestData, props) => {
    try {
        const response = await axiosInstanceInvoiceServer.post(url, requestData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const InvoiceDownloadByFormat = async (url, requestData, param, merchantName, date, method) => {

    try {
        let responseData = null
        let requestUrl = Api.invoice_host + "/" + url
        let contentType = 'application/pdf'

        if (param.format === 'pdf') {
            contentType = 'application/pdf'
        } else if (param.format === 'png') {
            contentType = 'image / png'
        } else if (param.format === 'excel') {
            contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        await axios({
            method: method,
            url: requestUrl,
            params: param,
            data: requestData,
            responseType: 'blob',
        }).then(response => {
            const today = dayjs().format("YYYY-MMM-DD HH:mm A")
            let fileName = date ? merchantName + "_Invoice_" + date : merchantName + "_Invoice_" + today
            const blob = new Blob([response.data], { type: contentType });
            if (blob.type === 'application/pdf') {
                fileName = fileName + ".pdf"
            } else if (blob.type === 'image / png') {
                fileName = fileName + ".png"
            } else if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileName = fileName + ".xlsx"
            }
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            responseData = response
        }).catch(error => {
            message.destroy();
            message.error("Fail to download")
            responseData = error
        });
        return responseData
    } catch (error) {
        throw error;
    }
}

const getMimeType = (fileType) => {
    switch (fileType) {
        case 'pdf':
            return 'application/pdf';
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'doc':
            return 'application/msword';
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'xls':
            return 'application/vnd.ms-excel';
        case 'png':
        case 'jpg':
        case 'jpeg':
            return 'image/png';
        default:
            return 'application/octet-stream'; // Generic binary stream
    }
};

// export const DownloadFileWithUrlNoAuth = async (fileUrl, fileName) => {
//     await axiosInstanceNoAuth.get(Api.file_download,
//         {
//             params: { fileName: fileUrl },
//             responseType: 'blob',
//         }).then(response => {
//             const fileType = fileUrl.split('.').pop().toLowerCase();
//             const blob = new Blob([response.data], {
//                 type: getMimeType(fileType),
//             });
//             const link = document.createElement('a');
//             link.href = URL.createObjectURL(blob);
//             link.download = fileName;
//             document.body.appendChild(link);
//             link.click();
//             link.remove();
//             URL.revokeObjectURL(link.href);
//         }).catch(error => {
//             message.error("Failed to download file")
//         });
//     return;
// };

export const urlChecker = async (url) => {
    try {
        await axios.get(url);
        return true
    } catch (error) {
        return false
    }
}

export const DownloadFileWithUrlNoAuth = async (fileUrl, fileName) => {
    const fileType = fileUrl.split('.').pop().toLowerCase();

    if (fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg') {
        await axiosInstanceNoAuth.get(Api.file_download,
            {
                params: { fileName: fileUrl },
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], {
                    type: getMimeType(fileType),
                });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const extension = fileType.startsWith('.') ? fileType : `.${fileType}`;
                const correctFileName = fileName.endsWith(extension) ? fileName : `${fileName}${extension}`;

                link.download = correctFileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(link.href);
            }).catch(error => {
            });
    } else {
        let presignedUrl = null
        await axiosInstanceNoAuth.get(Api.file_generate_presign_url, {
            params: { fileName: fileUrl },
        }).then(response => {
            presignedUrl = response?.data
        })
            .catch(error => { })
        if (presignedUrl) {
            if (urlChecker(presignedUrl)) {
                if (fileType === 'pdf') {
                    const response = await fetch(presignedUrl, { method: "GET", redirect: "follow" });

                    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    const extension = fileType.startsWith('.') ? fileType : `.${fileType}`;
                    const correctFileName = fileName.endsWith(extension) ? fileName : `${fileName}${extension}`;
                    link.download = correctFileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    window.location.href = presignedUrl;
                }
            } else {
                message.error("File to download")
            }
        } else {
            message.error("File to download")
        }
    }
};

