import {
    CloseOutlined,
    InfoCircleOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Checkbox, Col, ConfigProvider, Flex, Form, Image, message, Modal, Popconfirm, Radio, Row, Space, Spin, Typography } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import NinjaNote from '../../asset/ninja-note.png';
import { ModalTitle, NJVButton, NJVCard, NJVForm, NJVInput, NJVRadioGroup, NJVSelect, NJVTable } from '../../components/core-component';
import { SvgEmptyParcel, SvgInfoFilled, SvgRoyalCustomer } from '../../components/custom-svgIcon';
import Theme from '../../components/theme';
import { ParcelAmountValidator, PhoneNumberValidator, RestrictPhoneNumberValidator } from '../../core/validator';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler } from '../../network/network-manager';
import withRouter from '../../network/with-router';
import { updateAdvanceOrderList } from '../../redux/order-form-slice';
import InsuranceForm from './insurance-form';

const { Title } = Typography;

const HOME_DELIVERY = 1
const BUS_GATE = 2
const NINJA_POINT = 3
const POST_OFFICE = 4

const DELIVERY_FEES = 2000

const orderTypeMap = {
    '1': 'Home Delivery',
    '2': 'Bus Gate',
    '3': 'Ninja Point',
    '4': 'Post Office'
}

class ReceiverForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInput_collection: this.props.userInput_collection || [],
            form_activity: this.props.form_activity || null,
            pickupInformation: this.props.pickupInformation || null,
            isHomeDeliveryAvailable: this.props.isHomeDeliveryAvailable || false,
            isPostOfficeAvailable: this.props.isPostOfficeAvailable || false,
            isBusgateServiceAvailable: this.props.isBusgateServiceAvailable || false,
            isUpdateData: this.props.isUpdateData || false,

            openClearAllDataModal: false,
            isFetchingSameDay: false,
            isSameDayAvailable: false,
            isFetchingDivision: false,
            isFetchingBusGate: false,
            isFetchingPartnershipStore: false,
            isFetchingPriceScript: false,
            confirmRemoveInsuranceData: false,
            isLoyalCustomer: false,
            isInsuranceChecked: false,

            receiverInfo: null,
            receiverAddress_collection: [],
            receiverAddress_collection_home_delivery: [],
            receiverAddress_collection_bus_gate_service: [],
            divisionList: [],
            townshipList: [],
            busGateList: [],
            partnershipStoreList: [],
            deliveryFees: 0,
            selectedRowData: null,
            selectedRowKeys: [],
            warningLoyalModal: false,
            savedLoyalCustomer: false,
            codModal: false
        }
        this.orderFormRef = React.createRef();
    }

    componentDidMount = () => {
        this.fetchDivision()
        this.fetchProfile()
        this.fetchReceiverLocation()
        this?.props?.handleStateUpdate?.(this.updateStateFromTable)
        this?.props.handleEditOrder(this.editOrder)
    }

    componentDidUpdate = (prevProps) => {
        const updatedState = {};
        const keys = [
            "pickupInformation",
            "form_activity",
            "isUpdateData",
            "userInput_collection",
            "isHomeDeliveryAvailable",
            "isPostOfficeAvailable",
            "isBusgateServiceAvailable"
        ]

        keys.forEach(key => {
            if (this.props[key] !== prevProps[key]) {
                updatedState[key] = this.props[key]
            }
        })
        if (Object.keys(updatedState).length > 0) {
            this.setState(updatedState);
        }
        if (prevProps.form_activity !== this.props.form_activity) {

            this.orderFormRef.current?.setFieldsValue({
                orderType: this.props.form_activity?.orderType || undefined,
                paymentOption: null,
                address: null,
                note: null,
                townshipId: null,
                divisionId: null,
                parcelAmount: 0,
                favouriteLocationId: null,
                sameDay: false,
            });

            this.setState({
                receiverInfo: null,
                deliveryFees: 0,
                insuranceInfo: null,
                isInsuranceChecked: false
            })
        }
    }

    fetchProfile = async () => {
        try {
            this.setState({
                isFetchingProfile: true
            })
            const response = await ApiHandler({ url: Api.shipper_profile, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            this.setState({
                senderInfo: response,
                isFetchingProfile: false
            });
        } catch (error) {
        }
    }

    fetchReceiverLocation = async () => {
        this.setState({
            isFetchingReceiverLocation: true
        })
        try {
            const requestParams = { isMyLocation: false }
            const response = await ApiHandler({ url: Api.user_address_available, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let receiverAddressHomedelivery = []
                let receiverAddressBusGateService = []
                response.map(userAddress => {
                    if (userAddress && userAddress.township && userAddress.township.division) {
                        if (userAddress.township.enableHomeDelivery && userAddress.township.division.enableHomeDelivery) {
                            receiverAddressHomedelivery.push(userAddress)
                        }
                        if (userAddress.township.enableBusGatePostOfficeService) {
                            receiverAddressBusGateService.push(userAddress)
                        }
                    }
                })
                const sortedReceiverAddressHomedelivery = receiverAddressHomedelivery.sort((a, b) => {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                    return 0
                })
                const sortedReceiverAddressBusGateService = receiverAddressHomedelivery.sort((a, b) => {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                    return 0
                })

                this.setState({
                    receiverAddress_collection: sortedReceiverAddressHomedelivery,
                    receiverAddress_collection_bus_gate_service: sortedReceiverAddressBusGateService,
                    receiverAddress_collection_home_delivery: sortedReceiverAddressHomedelivery,
                    isFetchingReceiverLocation: false
                })
            }
        } catch (error) {
        }
    }

    fetchDivision = async () => {
        try {
            this.setState({
                isFetchingDivision: true
            })
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {

                let townshipListBusGateService = []
                let townshipListHomeDelivery = []
                response.map(data => {
                    data?.townshipDTOList.map(township => {
                        if (data.active) {
                            if (township.active && township.enableBusGatePostOfficeService) {
                                townshipListBusGateService.push({ ...township, division: { id: data.id, name: data.name } })
                            }
                            const sortedTownshipListBusGateService = townshipListBusGateService.sort((a, b) => {
                                if (a.name < b.name) return -1
                                if (a.name > b.name) return 1
                                return 0
                            })
                            this.setState({ townshipList: sortedTownshipListBusGateService })
                        }
                        if (data.active && data.enableHomeDelivery) {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push({ ...township, division: { id: data.id, name: data.name } })
                            }
                            const sortedTownshipListHomeDelivery = townshipListHomeDelivery.sort((a, b) => {
                                if (a.name < b.name) return -1
                                if (a.name > b.name) return 1
                                return 0
                            })
                            this.setState({ townshipList: sortedTownshipListHomeDelivery })
                        }
                    })
                })
                this.setState({
                    isFetchingDivision: false,

                    township_collection_home_delivery: townshipListHomeDelivery,
                    township_collection_bus_gate_service: townshipListBusGateService
                })
            }
        } catch (error) {
        }
    }

    fetchBusGate = async (senderTownshipId, receiverTownshipId) => {
        this.setState({
            isFetchingBusGate: true,
            busGateList: []
        })
        const requestParams = {
            'senderLocationId': senderTownshipId,
            'receiverTownshipId': receiverTownshipId
        }
        await ApiHandler({ url: Api.bus_gate_by_receiver_and_sender, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                if (response) {
                    this.setState({
                        busGateList: response,
                        isFetchingBusGate: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    isFetchingBusGate: false
                })
            })

    }

    fetchPartnershipStore = async (receiverTownshipId, partnershipStoreId) => {
        this.setState({
            isFetchingPartnershipStore: true,
            partnershipStoreList: []
        })
        const requestParams = {
            'townshipId': receiverTownshipId
        }
        await ApiHandler({ url: Api.order_partnership_stores, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                this.setState({
                    partnershipStoreList: response ? response : [],
                    isFetchingPartnershipStore: false
                })

            })
            .catch((error) => {
                this.setState({
                    isFetchingPartnershipStore: false
                })
            })

        if (partnershipStoreId) {
            this.orderFormRef.current.setFieldsValue({ partnershipStoreId: partnershipStoreId })
        }
    }

    checkSameDay = async (senderTownshipId, receiverTownshipId) => {
        this.setState({
            isFetchingSameDay: true,
            isSameDayAvailable: false
        })
        const requestParams = {
            'fromTownshipId': senderTownshipId,
            'toTownshipId': receiverTownshipId,
        }
        await ApiHandler({ url: Api.order_check_same_day, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    isSameDayAvailable: response,
                    isFetchingSameDay: false
                })
            })
            .catch(() => {
                this.setState({
                    isFetchingSameDay: false
                })
            })
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId, orderType, sameDay) => {

        this.setState({
            isFetchingPriceScript: true
        })
        const requestParams = {
            'senderTownshipId': senderTownshipId,
            'receiverTownshipId': receiverTownshipId,
            'orderType': orderTypeMap[orderType],
            'isSameDay': sameDay ? sameDay : false,
        }
        await ApiHandler({ url: Api.get_price, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then((response) => {
                if (response || response === 0) {
                    // this.orderFormRef.current.setFieldsValue({ deliveryFees: response })
                    this.setState({
                        deliveryFees: response
                    })
                }
                this.setState({
                    isFetchingPriceScript: false
                })
            })
            .catch((error) => {
                this.setState({
                    isFetchingPriceScript: false
                })
            })
    }

    handleActivity = (key, formData) => {
        let { form_activity, township_collection_home_delivery, township_collection_bus_gate_service, pickupInformation } = this.state

        if (key === 'townshipId') {
            let delivery_fees = 0
            let townshipList = []
            if (form_activity?.orderType === HOME_DELIVERY) {
                this.checkSameDay(pickupInformation?.townshipId, formData.value)
                this.getPriceScript(pickupInformation?.townshipId, formData.value, form_activity?.orderType)
                townshipList = township_collection_home_delivery
                this.orderFormRef.current.setFieldsValue({ sameDay: false })
                this.setState({
                    deliveryFees: 0
                })
            } else if (form_activity?.orderType === BUS_GATE) {
                delivery_fees = DELIVERY_FEES
                this.fetchBusGate(pickupInformation.townshipId, formData.value)
                townshipList = township_collection_bus_gate_service
                this.setState({
                    deliveryFees: delivery_fees
                })
            } else if (form_activity?.orderType === NINJA_POINT) {
                this.fetchPartnershipStore(formData.value)
                this.getPriceScript(pickupInformation.townshipId, formData.value, form_activity?.orderType)
            } else if (form_activity?.orderType === POST_OFFICE) {
                delivery_fees = DELIVERY_FEES
                townshipList = township_collection_bus_gate_service
                this.setState({
                    deliveryFees: delivery_fees
                })
            }
            this.orderFormRef.current.setFieldsValue({ busGateId: null, 'partnershipStoreId': null, 'partnershipStoreName': null, 'partnershipStoreAddress': null })

            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'townshipId': formData.value, 'townshipName': formData.label, 'busGateId': null, 'busGateName': null, 'divisionId': formData.divisionId, 'divisionName': formData.divisionName },
                townshipList
            }));
        } else if (key === 'orderType') {
            let delivery_fees = 0
            let payment_option = null
            const { receiverAddress_collection_bus_gate_service, receiverAddress_collection_home_delivery } = this.state;
            let loyalCustomers_collection = [];
            let townshipList = []

            if (formData === BUS_GATE || formData === POST_OFFICE) {
                delivery_fees = DELIVERY_FEES
                payment_option = 2
                townshipList = township_collection_bus_gate_service

                this.orderFormRef.current.setFieldsValue({ 'paymentOption': payment_option, 'townshipId': null, 'divisionId': null, 'townshipName': null })
                loyalCustomers_collection = receiverAddress_collection_bus_gate_service
                this.setState((prev) => ({
                    deliveryFees: delivery_fees,
                    form_activity: { ...prev.form_activity, favourite: false }
                }))
            } else if (formData === HOME_DELIVERY || formData === NINJA_POINT) {
                townshipList = township_collection_home_delivery

                this.orderFormRef.current.setFieldsValue({ 'paymentOption': payment_option, 'townshipId': null, 'divisionId': null, 'townshipName': null })
                loyalCustomers_collection = receiverAddress_collection_home_delivery
                this.setState((prev) => ({
                    deliveryFees: 0,
                    form_activity: { ...prev.form_activity, favourite: false }
                }))
            }

            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'paymentOption': payment_option, [key]: formData },
                insuranceInfo: null,
                isInsuranceChecked: false,
                busGateList: [],
                townshipList: townshipList,
                partnershipStoreList: [],
                receiverAddress_collection: loyalCustomers_collection,
                receiverInfo: { ...prevState.receiverInfo, townshipId: null }
            }));
            this.orderFormRef.current.setFieldsValue({ 'divisionId': null, 'townshipId': null, address: null, busGateId: null, partnershipStoreId: null, favouriteLocationId: null })
        } else if (key === 'busGateId') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'busGateId': formData.value, 'busGateName': formData.label }
            }));
        } else if (key === 'partnershipStoreId') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'partnershipStoreId': formData.value, 'partnershipStoreName': formData.label, 'partnershipStoreAddress': formData.address }
            }));
        } else if (key === 'paymentOption') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, paymentOption: formData }
            }));
        } else if (key === 'favourite') {
            this.setState(prevState => ({
                form_activity: { ...prevState.form_activity, 'favourite': formData }
            }));
        } else if (key === 'favouriteLocationId') {

            if (form_activity?.orderType === HOME_DELIVERY) {
                this.checkSameDay(pickupInformation.townshipId, formData?.township.id)
                this.getPriceScript(pickupInformation.townshipId, formData?.township.id, form_activity?.orderType)
                this.orderFormRef.current.setFieldsValue({ sameDay: false })
            } else if (form_activity?.orderType === BUS_GATE) {
                this.fetchBusGate(pickupInformation.townshipId, formData?.township.id)
            } else if (form_activity?.orderType === NINJA_POINT) {
                this.fetchPartnershipStore(formData?.township.id)
                this.getPriceScript(pickupInformation.townshipId, formData?.township.id, form_activity?.orderType)
                this.orderFormRef.current.setFieldsValue({ partnershipStoreId: null })
            }
            this.orderFormRef.current.setFieldsValue({ busGateId: null })
            this.setState(prevState => ({
                form_activity: {
                    ...prevState.form_activity,
                    favouriteLocationId: formData.id,
                    receiverName: formData.name,
                    phoneNumber: formData.phone,
                    address: formData.address1,
                    townshipId: formData?.township?.id,
                    townshipName: formData?.township?.name,
                    townshipPostalCode: formData?.township?.postalCode,
                    divisionId: formData?.township?.division?.id,
                    divisionName: formData?.township?.division?.name
                }
            }));
        } else if (key === 'sameDay') {
            if (formData)
                this.getPriceScript(pickupInformation.townshipId, form_activity.townshipId, form_activity?.orderType, true)
            else
                this.getPriceScript(pickupInformation.townshipId, form_activity.townshipId, form_activity?.orderType)
        }

        if (key === 'receiverName' || key === 'receiverPhone' || key === 'townshipId' || key === 'receiverAddress' || key === 'favourite' || key === 'favouriteLocationId') {

            let receiverData;
            if (key !== 'favourite') {
                receiverData = {
                    [key]: formData,
                    favourite: key === 'favouriteLocationId' ? true : false,
                }
            } else {
                receiverData = { [key]: formData }
            }

            this.setState((prev) => {
                const updatedReceiverInfo = {
                    ...prev.receiverInfo,
                    ...receiverData,
                };

                return {
                    receiverInfo: updatedReceiverInfo,
                    confirmRemoveInsuranceData: false,
                };
            });
        }
    }

    editOrder = (uid) => {
        const { userInput_collection, township_collection_home_delivery, township_collection_bus_gate_service, pickupInformation, receiverAddress_collection_home_delivery } = this.state

        let editOrder = {}
        userInput_collection.map(value => {
            if (value.id === uid) {
                editOrder = value
                return
            }
        })
        let townshipList = []
        if (editOrder.orderType === HOME_DELIVERY || editOrder.orderType === NINJA_POINT) {
            townshipList = township_collection_home_delivery
        } else {
            if (editOrder.orderType === BUS_GATE) {
                this.fetchBusGate(pickupInformation.townshipId, editOrder.townshipId)
            }
            townshipList = township_collection_bus_gate_service
        }
        if (editOrder.orderType === NINJA_POINT) {
            this.fetchPartnershipStore(editOrder.townshipId, editOrder.partnershipStoreId)
        }

        this.orderFormRef.current.setFieldsValue({ ...editOrder, partnershipStoreId: null })
        const favouriteLocation = receiverAddress_collection_home_delivery.find(address => address.id === editOrder.favouriteLocationId)

        const receiverInfo = {
            "favouriteLocationId": {
                "name": favouriteLocation?.name,
                "address1": favouriteLocation?.address1,
                "township": {
                    "division": {
                        name: favouriteLocation?.township.division.name
                    },
                    name: favouriteLocation?.township.name
                }
            },
            "favourite": editOrder.favourite,
            "receiverName": editOrder.receiverName,
            "townshipId": {
                label: editOrder.townshipName,
                divisionName: editOrder.divisionName
            },
            "receiverAddress": editOrder.address
        }

        this.props?.updateStateToTable('is_update', true)

        this.setState({
            form_activity: editOrder,
            deliveryFees: editOrder.deliveryFees,
            receiverInfo,
            insuranceInfo: editOrder.insuranceInfo,
            townshipList,
            isInsuranceChecked: editOrder.isExistInsurance
        })
    }

    handleAddReceiver = (formData) => {
        const { form_activity, isFetchingPriceScript, userInput_collection, pickupInformation, insuranceInfo, deliveryFees } = this.state
        const uid = uuidv4()
        let isUpdate = false
        let currentReceiverData = {}
        currentReceiverData = { ...form_activity }
        currentReceiverData = { ...currentReceiverData, ...formData }
        if (currentReceiverData.orderType === NINJA_POINT) {
            currentReceiverData = { ...currentReceiverData, address: currentReceiverData.partnershipStoreAddress }
        }
        if (currentReceiverData.id === null || currentReceiverData.id === '' || currentReceiverData.id === undefined) {
            currentReceiverData = { ...currentReceiverData, id: uid, key: uid }
        } else {
            isUpdate = true
        }
        if ((form_activity?.orderType === HOME_DELIVERY || form_activity?.orderType === NINJA_POINT) && isFetchingPriceScript) {
            message.error("Please wait price script is fetching")
            return
        }
        currentReceiverData = {
            ...currentReceiverData,
            parcelAmount: formData.parcelAmount ? +formData.parcelAmount : 0,
            deliveryFees: deliveryFees ? +deliveryFees : 0,
        }
        let total = 0
        if ((currentReceiverData.orderType === HOME_DELIVERY || currentReceiverData.orderType === NINJA_POINT)) {
            if (currentReceiverData.paymentOption === 1) {
                total = Number(formData.parcelAmount) + Number(deliveryFees)
            } else if (currentReceiverData.paymentOption === 2) {
                total = Number(formData.parcelAmount)
            }

        }
        if (insuranceInfo) {
            currentReceiverData = { ...currentReceiverData, total, isExistInsurance: insuranceInfo?.isExistInsurance, insuranceInfo };
        } else {
            currentReceiverData = { ...currentReceiverData, total, isExistInsurance: false, insuranceInfo: null };
        }

        let new_userinput_collection = []
        if (isUpdate) {
            new_userinput_collection = userInput_collection?.map(oldReceiverData => {
                if (oldReceiverData.id === currentReceiverData.id) {
                    return currentReceiverData
                } else {
                    return oldReceiverData
                }
            })
        } else {
            if (userInput_collection)
                new_userinput_collection = [...userInput_collection, currentReceiverData]
            else
                new_userinput_collection = [currentReceiverData]
        }
        if (deliveryFees === 0) {
            message.destroy()
            message.error(`You can't ${isUpdate ? 'Update' : 'Add'} order if delivery fees 0`)
            return
        }
        if (total > 1000000) {
            this.setState({ codModal: true });
        } else {
            this.props?.updateStateToTable('update_userInput', new_userinput_collection)
            this.props?.updateStateToTable('is_update', false)
            this.setState(prev => ({
                form_activity: { favourite: false, orderType: HOME_DELIVERY },
                busGateList: [],
                partnershipStoreList: [],
                insuranceInfo: null,
                isInsuranceChecked: false,
                confirmRemoveInsuranceData: true,
                receiverInfo: {
                    ...prev.receiverInfo,
                    receiverName: null,
                    receiverPhone: null,
                    divisionId: null,
                    townshipId: null,
                    receiverAddress: null,
                    favouriteLocationId: null
                },
                deliveryFees: 0
            }))

            this.orderFormRef.current.setFieldsValue({
                orderType: HOME_DELIVERY,
                address: null,
                note: null,
                phoneNumber: null,
                townshipId: null,
                receiverName: null,
                divisionId: null,
                parcelAmount: 0,
                paymentOption: null,
                favouriteLocationId: null,
                sameDay: false,
                partnershipStoreId: null
            })
            if (isUpdate) {
                message.success(`${i18next.t("success_msg_new_receiver_updated")}`)
            } else {
                message.success(`${i18next.t("success_msg_new_receiver_added")}`)
            }
            this.props.dispatch(updateAdvanceOrderList({ orderList: new_userinput_collection, pickupAddress: pickupInformation }))
        }
    }

    handleSaveLoyalCustomer = async () => {
        const { receiverInfo } = this.state

        if (!receiverInfo.receiverName || !receiverInfo.receiverPhone || !receiverInfo.receiverAddress || !receiverInfo.townshipId.value) {
            message.error("Please enter required fields!")
            return
        }

        this.setState({
            is_saving_data: true
        })
        try {
            const response = await ApiHandler({
                url: Api.user_address, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "name": receiverInfo.receiverName,
                    "phone": receiverInfo.receiverPhone,
                    "address1": receiverInfo.receiverAddress,
                    "township": {
                        "id": receiverInfo.townshipId.value
                    },
                    "defaultLocation": false,
                    "myLocation": false
                }
            }
            )
            if (response.status === 200) {
                this.setState({
                    openSaveLoyalModal: false,
                    savedLoyalCustomer: true
                })
                this.fetchReceiverLocation();
            }
        } catch (error) {
            if (error && error.response.data.code === "044") {
                message.error(error.response.data.message)
                this.setState({
                    isCreatingParcel: false
                });
            }
        }
        this.setState({
            is_saving_data: false
        })
    }

    handleLoyalCustomerSearch = (value) => {
        const filtered = this.state.receiverAddress_collection?.filter(address =>
            address.name.toLowerCase().includes(value.toLowerCase())
        )

        this.setState({
            filtered_receiverAddress_collection: filtered,
            isLoyalCustomerSearch: true,
            loyaltySearchValue: value
        })
    }

    clearLoyaltySearch = () => {
        this.setState({
            openLoyalCustomerModal: false,
            selectedRowKeys: [],
            filtered_receiverAddress_collection: [],
            isLoyalCustomerSearch: false,
            loyaltySearchValue: '',
        })
    }

    clearInput = () => {
        this.orderFormRef?.current?.setFieldsValue({
            address: null,
            note: null,
            phoneNumber: null,
            townshipId: null,
            receiverName: null,
            divisionId: null,
            parcelAmount: 0,
            paymentOption: null,
            favouriteLocationId: null,
            orderType: HOME_DELIVERY,
            sameDay: false,
        })

        this.props.updateStateToTable('is_update', false)
        this.setState({
            form_activity: { orderType: HOME_DELIVERY, favourite: false },
            receiverInfo: null,
            deliveryFees: 0,
            insuranceInfo: null,
            isInsuranceChecked: false
        })
    }

    changeParcelAmount = (inputValue) => {
        if (inputValue && inputValue[0] === '0') {
            inputValue = inputValue.slice(1);
        }
        if (inputValue === '') {
            inputValue = 0
        }
        this.orderFormRef?.current?.setFieldsValue({ parcelAmount: inputValue })
    }

    handleReceiverData = (updatedFields) => {
        this.orderFormRef?.current?.setFieldsValue(updatedFields)
    }

    handleInsuranceData = (data) => {
        this.setState({ insuranceInfo: data })
    }

    handleInsuranceCheckbox = (checked) => {
        this.setState({
            isInsuranceChecked: checked
        })
    }

    handleRemoveStatus = (value) => {
        this.setState({
            confirmRemoveInsuranceData: value
        })
    }

    onChangePagination = (currentPage) => {
        this.setState({
            page: currentPage.current
        })
    }

    render() {
        const {
            isSameDayAvailable,
            townshipList,
            isFetchingBusGate,
            isFetchingPartnershipStore,
            isFetchingPriceScript,
            busGateList,
            partnershipStoreList,
            senderInfo,
            confirmRemoveInsuranceData,
            deliveryFees,
            form_activity,
            openLoyalCustomerModal,
            selectedRowData,
            openSaveLoyalModal,
            receiverInfo,
            is_saving_data,
            receiverAddress_collection,
            isFetchingReceiverLocation,
            isLoyalCustomerSearch,
            filtered_receiverAddress_collection,
            isFetchingDivision,
            isUpdateData,
            isHomeDeliveryAvailable,
            isPostOfficeAvailable,
            isBusgateServiceAvailable,
            isInsuranceChecked,
            selectedRowKeys,
            warningLoyalModal,
            savedLoyalCustomer,
            loyaltySearchValue,
            codModal
        } = this.state

        const { isMobileSize } = this.props;        

        const customizeRenderEmpty = () => (
            <Flex justify='center' align='center' vertical style={{ marginTop: 30 }}
            >
                <Flex justify='center' align='center' style={{ width: 80, height: 80, borderRadius: '50%', position: 'relative', background: 'rgba(253, 245, 245, 1)' }}>
                    <SvgEmptyParcel width={30} height={30} color="rgba(194, 0, 47, 0.3)" style={{ position: 'absolute' }} />
                </Flex>
                <p style={{ color: '#000' }}>No Loyal Customer Information</p>
            </Flex>
        );

        let columns = [
            {
                title: <>{i18next.t("name")}</>,
                dataIndex: 'name',
                key: '1'
            },
            {
                title: <>{i18next.t("phone_number")}</>,
                dataIndex: 'phone',
                key: '2',
                responsive: ['md'],
            },
            {
                title: <>{i18next.t("township")}</>,
                dataIndex: 'townshipName',
                key: '3',
                render: (_, record) => <>{record?.township?.name}</>
            },
            // {
            //     title: 'Action',
            //     key: '4',
            //     render: (record) => <Link to={`${CustomPath.user_address_update}/${record.id}`} state={{prevRoute : CustomPath.order_create}}><span style={{ color: Theme.colors.primary, textDecoration: 'underline', fontWeight: 500 }} className='pointer'>Manage</span></Link>,
            //     width: 10
            // },
        ]

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowData: selectedRows,
                    selectedRowKeys
                })
            }
        };

        return (
            <>
                <ConfigProvider
                    theme={{
                        token: {
                            paddingContentHorizontalLG: isMobileSize ? 5 : 24,
                        }
                    }}
                >
                    <Modal
                        title={
                            null
                        }
                        open={openLoyalCustomerModal}
                        onCancel={() => {
                            this.handleActivity('favourite', false)
                            this.clearLoyaltySearch()
                        }
                        }
                        width={!isMobileSize && 800}
                        footer={null}
                        closeIcon={null}
                        style={{ padding: isMobileSize ? '20px 10px' : '20px 24px', textAlign: 'right', top: 10 }}
                    >
                        <Flex justify='space-between' align='center' style={{ marginBottom: 12 }}>
                            <ModalTitle label={i18next.t("loyal_customer")} />
                            <Flex align='center'>
                                {
                                    !isMobileSize &&
                                    <NJVInput
                                        value={loyaltySearchValue}
                                        inputprimary={Theme.colors.secondary}
                                        inputborder={Theme.colors.secondary}
                                        style={{ width: 200, marginRight: 10 }}
                                        issearchinput="true"
                                        onChange={(e) => this.handleLoyalCustomerSearch(e.target.value)}
                                        placeholder={i18next.t("customer_name")}></NJVInput>
                                }
                                <CloseOutlined className='pointer' onClick={() => {
                                    this.clearLoyaltySearch()
                                }} />
                            </Flex>
                        </Flex>
                        {
                            isMobileSize &&
                            <NJVInput
                                value={loyaltySearchValue}
                                inputprimary={Theme.colors.secondary}
                                inputborder={Theme.colors.secondary}
                                style={{ width: '100%', marginBottom: 10 }}
                                issearchinput="true"
                                onChange={(e) => this.handleLoyalCustomerSearch(e.target.value)}
                                placeholder={i18next.t("customer_name")}></NJVInput>
                        }
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        rowSelectedBg: '#fff',
                                        rowSelectedHoverBg: '#fff'
                                    }
                                }
                            }}
                            renderEmpty={customizeRenderEmpty}>
                            <NJVTable
                                rowKey="id"
                                rowSelection={{
                                    type: 'radio',
                                    ...rowSelection,
                                }}
                                headerbgcolor="#F7F7F7"
                                headerborderradius='0px'
                                cellinline="3px"
                                columns={columns}
                                loading={isFetchingReceiverLocation}
                                dataSource={isLoyalCustomerSearch ? filtered_receiverAddress_collection : receiverAddress_collection}
                                // onChange={this.onChangePagination}
                                footer={null}
                            />
                        </ConfigProvider>
                        {/* {
                            selectedRowKeys?.length === 0 ?
                                <Link to={CustomPath.user_address_create} state={CustomPath.order_create}>
                                    <NJVButton
                                        type="primary"
                                        style={{ width: 125 }}
                                        buttonprimary="#717171"
                                        onClick={() => {
                                            this.setState({ openLoyalCustomerModal: false })
                                        }}
                                    >Add</NJVButton>
                                </Link>
                                : */}
                        <NJVButton
                            disabled={selectedRowKeys?.length === 0}
                            buttonprimary={Theme.colors.primary}
                            type="primary" style={{ width: 125 }}
                            onClick={() => {
                                if (selectedRowData) {
                                    this.handleActivity('favouriteLocationId', selectedRowData[0])
                                    this.handleActivity('favourite', true)
                                }
                                this.clearLoyaltySearch()
                            }}>{i18next.t("select")}</NJVButton>
                        {/* } */}
                    </Modal>
                </ConfigProvider>
                <Modal
                    title={null}
                    open={openSaveLoyalModal}
                    onCancel={() => this.setState({ openSaveLoyalModal: false })}
                    closable={false}
                    footer={null}
                    width={350}>
                    <Flex vertical align='center' justify='center' gap={20}>
                        <Image src={NinjaNote} preview={false} alt='ninja note' width={100} height={150} />
                        <span style={{ fontWeight: 500, textAlign: 'center', fontSize: 13 }}>{i18next.t("msg_loyal_customer")}</span>
                        <Space>
                            <NJVButton onClick={() => this.setState({ openSaveLoyalModal: false })} style={{ width: 125, color: Theme.colors.primary }}>{i18next.t("cancel")}</NJVButton>
                            <NJVButton
                                buttonprimary={Theme.colors.primary}
                                type="primary"
                                loading={is_saving_data}
                                style={{ width: 125 }}
                                onClick={() => {
                                    this.handleSaveLoyalCustomer();
                                }}
                            >{i18next.t("save")}</NJVButton>
                        </Space>
                    </Flex>
                </Modal>
                <Modal
                    closeIcon={null}
                    centered
                    width={!isMobileSize && 400}
                    title={null}
                    open={warningLoyalModal}
                    onCancel={() => this.setState({ warningLoyalModal: false })}
                    footer={
                        <NJVButton buttonprimary={Theme.colors.primary} onClick={() => this.setState({ warningLoyalModal: false })} type="primary">{i18next.t('confirm')}</NJVButton>
                    }
                >
                    <p style={{ fontSize: 15 }}>Please complete receiver information form first.</p>
                </Modal>
                <Modal
                    closeIcon={null}
                    centered
                    title={
                        <>
                            <InfoCircleOutlined style={{ color: "blue", marginRight: 8 }} />
                            Notice
                        </>}
                    open={codModal}
                    onCancel={() => this.setState({ codModal: false })}
                    footer={
                        <NJVButton buttonprimary={Theme.colors.primary} type="primary"
                            onClick={() => {
                                this.setState({
                                    codModal: false
                                })
                            }}>{i18next.t('confirm')}</NJVButton>
                    }
                >
                    <p style={{ fontSize: 15 }}>{i18next.t("cod_notice")}</p>
                </Modal>
                <NJVForm
                    ref={this.orderFormRef}
                    layout="vertical"
                    initialValues={{
                        'orderType': form_activity?.orderType
                    }}
                    onFinish={this.handleAddReceiver}
                >
                    <Row gutter={[8, 0]}>

                        <Col span={24}>
                            <Form.Item name="orderType">
                                <NJVRadioGroup onChange={(e) => this.handleActivity('orderType', e.target.value)}>
                                    {
                                        isHomeDeliveryAvailable && <Radio value={HOME_DELIVERY}>{i18next.t('home_delivery')}</Radio>
                                    }
                                    {
                                        isBusgateServiceAvailable &&
                                        <Radio value={BUS_GATE}>{i18next.t('bus_gate')}</Radio>
                                    }

                                    {
                                        isPostOfficeAvailable ?
                                            <Radio value={POST_OFFICE}>{i18next.t('post_office')}</Radio>
                                            : <></>
                                    }
                                    {/* <Radio value={NINJA_POINT}>{i18next.t('ninja_point')}</Radio> */}
                                </NJVRadioGroup>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <Flex justify='space-between' align='center'>
                                <Title level={5} style={{ marginBlock: 0 }}>{i18next.t("receiver_info")}</Title>
                                <span
                                    style={{ textDecoration: 'underline', color: Theme.colors.primary, fontWeight: 600, cursor: 'pointer' }}
                                    // onClick={(event) => this.handleActivity('favourite', event.target.checked)}
                                    onClick={() => this.setState({ openLoyalCustomerModal: true })}
                                >{i18next.t("loyal_customer")}</span>
                            </Flex>
                        </Col>
                        {
                            form_activity?.favourite ?
                                <>
                                    <Col span={24}>
                                        <Form.Item label={i18next.t("loyal_customer")}
                                            rules={[
                                                {
                                                    required: true,
                                                }
                                            ]}
                                        >
                                            <NJVInput
                                                style={{
                                                    width: '100%',
                                                }}
                                                size="large"
                                                value={form_activity?.receiverName}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Form.Item label={i18next.t("phone_number")}
                                            rules={[
                                                { validator: PhoneNumberValidator },
                                                { required: true, message: `${i18next.t("err_msg_enter_receiver_phone_number")}` }]}
                                        >
                                            <NJVInput
                                                value={form_activity?.phoneNumber}
                                                bgcolor={Theme.colors.light_gray} size="large" placeholder="09" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={12} sm={24} xs={24}>
                                        <Form.Item label={i18next.t("township_city")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_select_township")}`
                                                }
                                            ]}
                                        >
                                            <NJVSelect
                                                value={form_activity?.townshipId}
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                loading={isFetchingDivision}
                                                disabled
                                                placeholder={i18next.t("township_city")}
                                                size="large"
                                                optionFilterProp="label"
                                                options={
                                                    townshipList && townshipList?.map((township) => {
                                                        return {
                                                            label: township.division?.name,
                                                            options: [
                                                                {
                                                                    value: township.id,
                                                                    divisionId: township.division.id,
                                                                    divisionName: township.division.name,
                                                                    label: `${township.name}(${township.nameMM})`,
                                                                    type: 'townshipId',
                                                                    postalCode: township.postalCode,
                                                                }
                                                            ]
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={i18next.t("address")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_enter_address")}`
                                                }
                                            ]}
                                        >
                                            <NJVInput
                                                bgcolor={Theme.colors.light_gray}
                                                size="large"
                                                showCount
                                                value={form_activity?.address}
                                                style={{
                                                    width: '100%',
                                                }}
                                                maxLength={180}
                                                istextareainput="true"
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        form_activity && form_activity?.orderType === HOME_DELIVERY &&
                                        <Col span={24}>
                                            <Form.Item name="sameDay" valuePropName="checked">
                                                <Checkbox
                                                    disabled={!isSameDayAvailable}
                                                    onChange={(event) => this.handleActivity('sameDay', event.target.checked)}
                                                >
                                                    {i18next.t("same_day")}
                                                </Checkbox>
                                            </Form.Item>

                                        </Col>
                                    }
                                </>
                                :
                                <>
                                    <Col lg={24} md={12} sm={12} xs={12}>
                                        <Form.Item name="receiverName" label={i18next.t("name")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_enter_receiver_name")}`
                                                }
                                            ]}
                                        >
                                            <NJVInput bgcolor={Theme.colors.light_gray} size="large" onChange={(e) => this.handleActivity('receiverName', e.target.value)} />
                                        </Form.Item>

                                    </Col>
                                    <Col lg={24} md={12} sm={12} xs={12}>
                                        <Form.Item name="phoneNumber" label={i18next.t("phone_number")}
                                            rules={[
                                                { validator: RestrictPhoneNumberValidator },
                                                { required: true, message: `${i18next.t("err_msg_enter_receiver_phone_number")}` }]}
                                        >
                                            <NJVInput bgcolor={Theme.colors.light_gray} size="large" placeholder="09" onChange={(e) => this.handleActivity('receiverPhone', e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    {
                                        form_activity && (form_activity?.orderType === HOME_DELIVERY || form_activity?.orderType === POST_OFFICE) &&
                                        <Col span={24}>
                                            <Form.Item name="address" label={i18next.t("address")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: `${i18next.t("err_msg_enter_address")}`
                                                    }
                                                ]}
                                            >
                                                <NJVInput
                                                    bgcolor={Theme.colors.light_gray}
                                                    size="large"
                                                    showCount
                                                    onChange={(e) => this.handleActivity('receiverAddress', e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    maxLength={180}
                                                    istextareainput="true"
                                                />
                                            </Form.Item>
                                        </Col>
                                    }

                                    <Col lg={24} md={12} sm={24} xs={24}>
                                        <Form.Item name="townshipId" label={i18next.t("township_city")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_select_township")}`
                                                }
                                            ]}
                                        >
                                            <NJVSelect
                                                showSearch
                                                style={{
                                                    width: '100%',
                                                }}
                                                loading={isFetchingDivision}
                                                placeholder={i18next.t("choose_township")}
                                                size="large"
                                                optionFilterProp="label"
                                                onChange={(key, value) => this.handleActivity('townshipId', value)}
                                                options={
                                                    townshipList && townshipList?.map((township) => {
                                                        return {
                                                            label: township.division?.name,
                                                            options: [
                                                                {
                                                                    value: township.id,
                                                                    divisionId: township.division.id,
                                                                    divisionName: township.division.name,
                                                                    label: `${township.name}(${township.nameMM})`,
                                                                    type: 'townshipId',
                                                                    postalCode: township.postalCode,
                                                                }
                                                            ]
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        form_activity && form_activity?.orderType === HOME_DELIVERY &&
                                        <Col lg={24} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: 13 }}>
                                            <Form.Item name="sameDay" valuePropName="checked" style={{ marginBottom: 0 }}>
                                                <Checkbox
                                                    disabled={!isSameDayAvailable}
                                                    onChange={(event) => this.handleActivity('sameDay', event.target.checked)}
                                                >
                                                    {i18next.t("same_day")}
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                    }
                                </>
                        }
                        {
                            form_activity?.orderType === 2 ?
                                <>
                                    <Col lg={24} md={12} xs={24} sm={24}>
                                        <Form.Item name="busGateId" label={i18next.t("bus_gate_name")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_select_bus_gate")}`
                                                }
                                            ]}
                                        >
                                            <NJVSelect
                                                style={{
                                                    width: '100%',
                                                }}
                                                loading={isFetchingBusGate}
                                                placeholder={i18next.t("bus_gate_name")}
                                                size="large"
                                                onChange={(key, value) => this.handleActivity('busGateId', value)}
                                                options={
                                                    busGateList && busGateList.map((busGate) => {
                                                        return {
                                                            value: busGate.id,
                                                            label: busGate.busGateName,
                                                            type: 'busGateId'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                                :
                                <>
                                </>
                        }
                        {
                            form_activity?.orderType === 3 ?
                                <>
                                    <Col lg={24} md={12} xs={24} sm={24}>
                                        <Form.Item name="partnershipStoreId" label={i18next.t("partner_store")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `${i18next.t("err_msg_select_partner_store")}`
                                                }
                                            ]}
                                        >
                                            <NJVSelect
                                                style={{
                                                    width: '100%',
                                                }}
                                                loading={isFetchingPartnershipStore}
                                                bgcolor={Theme.colors.light_gray}
                                                placeholder={i18next.t("partner_store")}
                                                size="large"
                                                onChange={(key, value) => this.handleActivity('partnershipStoreId', value)}
                                                options={
                                                    partnershipStoreList && partnershipStoreList.map((partnershipStore) => {
                                                        return {
                                                            value: partnershipStore.id,
                                                            label: partnershipStore.fullName,
                                                            address: partnershipStore.address,
                                                            type: 'partnershipStoreId'

                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                                :
                                <></>
                        }
                        {
                            (!savedLoyalCustomer && !form_activity?.favourite) &&
                            <Col span={24}
                                style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', gap: 5, marginBottom: 13, cursor: 'pointer' }}
                                onClick={() => {
                                    if (!receiverInfo?.receiverName && !receiverInfo?.receiverPhone && !receiverInfo?.townshipId) {
                                        this.setState({ warningLoyalModal: true })
                                    } else {
                                        this.setState({ openSaveLoyalModal: true })
                                    }
                                }}
                            >
                                <SvgRoyalCustomer width={20} height={20} />
                                <span style={{ color: Theme.colors.primary, textDecoration: 'underline', fontWeight: '500' }}>{i18next.t('save_loyal_customer')}</span>
                            </Col>
                        }
                        <Col lg={12} md={12} xs={12} sm={12} >
                            <Form.Item name="parcelAmount" label={i18next.t("parcel_info")}
                                rules={[
                                    {
                                        required: true,
                                        message: `${i18next.t("err_msg_enter_parcel_amount")}`
                                    },
                                    {
                                        validator: ParcelAmountValidator,
                                    },
                                ]}
                            >
                                <NJVInput
                                    bgcolor={Theme.colors.light_gray}
                                    type='text'
                                    name="parcelAmount"
                                    placeholder={i18next.t("parcel_value")}
                                    size="large"
                                    onChange={e => this.changeParcelAmount(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Flex gap={5}>
                                <span>{i18next.t("delivery_fees")}: </span>
                                {isFetchingPriceScript ? (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined
                                                style={{
                                                    fontSize: 18,
                                                    color: Theme.colors.primary,
                                                }}
                                                spin
                                            />
                                        }
                                    />
                                ) : (
                                    <span>
                                        {deliveryFees ? deliveryFees : 0} MMK
                                    </span>
                                )}
                            </Flex>
                        </Col>
                        {
                            (form_activity?.orderType === HOME_DELIVERY || form_activity?.orderType === NINJA_POINT) &&
                            <Col lg={24} md={24} xs={24} sm={24} >
                                <Form.Item name="paymentOption" label={i18next.t("payment_option")}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${i18next.t("err_msg_select_payment_option")}`
                                        }
                                    ]}
                                >
                                    <NJVRadioGroup>
                                        <Radio
                                            value={1}
                                            disabled={(form_activity?.orderType === BUS_GATE || form_activity?.orderType === POST_OFFICE) ? true : false}
                                            onChange={() => this.handleActivity('paymentOption', 1)}
                                        >
                                            {i18next.t("customer_payment")}
                                        </Radio>
                                        <Radio
                                            value={2}
                                            onChange={() => this.handleActivity('paymentOption', 2)}
                                        >
                                            {i18next.t("shipper_payment")}
                                        </Radio>
                                    </NJVRadioGroup>
                                </Form.Item>
                            </Col>
                        }
                        {
                            form_activity?.orderType === BUS_GATE ?
                                <Col span={24} style={{ marginTop: 10 }}>
                                    <Row>
                                        <Col span={10} style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
                                            <Form.Item name="busGateFees" valuePropName='checked' style={{ marginBottom: 0 }}>
                                                <Checkbox>{i18next.t("pay_bus_gate_fees")}</Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col span={14} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15 }}>
                                            <NJVCard cardpadding="10px 5px" cardbgcolor="rgba(238, 238, 238, 1)" style={{ width: '100%' }}>
                                                <Flex justify='center' align='center' gap={8}>
                                                    <SvgInfoFilled width={20} height={20} color="rgba(250, 201, 23, 1)" />
                                                    <span style={{ fontWeight: 'bold', fontSize: 13 }}>{i18next.t("msg_payby_shipper")}</span>
                                                </Flex>
                                            </NJVCard>
                                        </Col>
                                    </Row>
                                </Col>
                                :
                                <></>
                        }
                        {
                            form_activity?.orderType === POST_OFFICE &&
                            <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15, marginTop: 10 }}>
                                <NJVCard cardbgcolor="rgba(238, 238, 238, 1)" style={{ width: '100%' }}>
                                    <Flex justify='center' align='center' gap={8}>
                                        <SvgInfoFilled width={20} height={20} color="rgba(250, 201, 23, 1)" />
                                        <span style={{ fontWeight: 'bold', fontSize: 13 }}>{i18next.t("msg_payby_shipper")}</span>
                                    </Flex>
                                </NJVCard>
                            </Col>
                        }
                        <Col span={24}>
                            <Form.Item name="note">
                                <NJVInput
                                    bgcolor={Theme.colors.light_gray}
                                    name="note"
                                    showCount
                                    maxLength={100}
                                    style={{
                                        width: '100%',
                                        height: 80
                                    }}
                                    placeholder={i18next.t("note_text")}
                                    istextareainput="true"
                                />
                            </Form.Item>
                        </Col>
                        {
                            form_activity?.orderType === HOME_DELIVERY && (
                                <Col span={24}>
                                    <InsuranceForm
                                        senderData={senderInfo} receiverData={receiverInfo}
                                        handleReceiverData={this.handleReceiverData}

                                        insuranceData={this.state.insuranceInfo}
                                        handleInsuranceData={this.handleInsuranceData}

                                        removeStatus={confirmRemoveInsuranceData}
                                        handleRemoveStatus={this.handleRemoveStatus}

                                        isInsurance={isInsuranceChecked}
                                        handleInsuranceCheckbox={this.handleInsuranceCheckbox}
                                    />
                                </Col>
                            )
                        }
                        {
                            <>
                                <Col span={24}>
                                    <Flex justify='end'>
                                        <NJVButton type="text" onClick={() => this.clearInput()} style={{ marginRight: 10 }}>{i18next.t("clear")}</NJVButton>
                                        <Form.Item style={{ marginBottom: 0 }}>
                                            <NJVButton type="primary" htmlType="submit" buttonprimary={Theme.colors.primary}>
                                                {
                                                    isUpdateData ? <span>Update</span> : <span>{i18next.t("add_receiver")}</span>
                                                }
                                            </NJVButton>
                                        </Form.Item>
                                    </Flex>
                                </Col>
                            </>
                        }
                    </Row >
                </NJVForm >
            </>
        )
    }
}

export default compose(withRouter)(ReceiverForm)