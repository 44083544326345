import { compose } from "@reduxjs/toolkit";
import { Col, ConfigProvider, Flex, Image, Row, Segmented, Typography } from 'antd';
import i18next from "i18next";
import cookies from "js-cookie";
import React from "react";
import logo from "../../asset/Logo.png";
import { SvgMyanmarFlag, SvgUKFlag } from "../../components/custom-svgIcon";
import Theme from "../../components/theme";
import '../../css/login.css';
import IMAGE_URL from "../../network/imageUrl";
import withRouter from "../../network/with-router";
import ForgotPassword from "./forgot-password";
import Login from "./login";
import Register from "./register";

const { Title, Text } = Typography;

const successRegisterImage = require('../../asset/register-success.png')

// const directUrl = 'http://bit.ly/4bKeZGI'

class LandingPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneNumber: '', visiblePassword: false, loggingIn: false, currentLanguage: 'en', isOwed: false,
            tabValue: 'LOGIN',
            openSuccessPage: false,
            openForgotPasswordPage: false
        }
        this.formRef = React.createRef()
    }

    componentDidMount = () => {
        const currentLanCode = cookies.get("i18next") || "en"
        this.setState({
            currentLanguage: currentLanCode
        })
        document.body.style.zoom = "100%";
    }

    showPassword = value => {
        this.setState({ visiblePassword: value.target.checked })
    }

    changeLanguage = () => {
        const { currentLanguage } = this.state
        if (currentLanguage === 'en') {
            i18next.changeLanguage("mm")
            this.setState({
                currentLanguage: 'mm'
            })
        } else if (currentLanguage === 'mm') {
            i18next.changeLanguage("en")
            this.setState({
                currentLanguage: 'en'
            })
        }
    }

    handleSuccessPage = (value) => {
        this.setState({
            openSuccessPage: value
        })
    }

    handleForgotPage = (value) => {
        this.setState({
            openForgotPasswordPage: value
        })
    }

    handleInitialState = () => {
        this.setState({ tabValue: 'LOGIN', openSuccessPage: false, openForgotPasswordPage: false })
    }

    render() {
        const { currentLanguage, tabValue, openSuccessPage, openForgotPasswordPage } = this.state
        const currentYear = new Date().getFullYear();

        return (<>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: "Radio Canada"
                    }
                }}
            >
                <Row style={{ height: '100vh', alignItems: 'center' }}>
                    <Col lg={0} md={0} sm={2} xs={1} />
                    <Col lg={12} md={12} sm={0} xs={0}>
                        <div style={{
                            backgroundColor: `${Theme.colors.primary}`,
                            textAlign: 'center',
                            height: '100vh',
                            position: 'relative',
                        }}>
                            {/* <div style={{ paddingTop: 80 }}>
                                <Title level={2} style={{ color: '#fff', marginTop: 0 }}>Welcome to ninjavan</Title>
                                <Title level={5} style={{ color: '#fff', marginTop: 0, fontWeight: 300 }}>Delivering
                                    delight one parcel at a time</Title>
                            </div> */}
                            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                                <Image
                                    src={IMAGE_URL.landing_img}
                                    preview={false}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={20} xs={22}
                    >
                        <Row gutter={tabValue === 'LOGIN' || openSuccessPage ? [0, 140] : [0, 0]}>
                            <Col lg={2} md={1} sm={2} xs={2} />
                            <Col lg={20} md={22} sm={20} xs={20}>
                                <Row>
                                    <Col span={24}>
                                        <Flex justify={'space-between'}>
                                            <Image
                                                width={190}
                                                src={logo}
                                                preview={false}
                                                style={{ marginBottom: 10, cursor: 'pointer' }}
                                                onClick={() => this.handleInitialState()}
                                            />
                                            <span onClick={() => this.changeLanguage()}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {currentLanguage === 'en' ? <SvgUKFlag width={35} height={35} /> :
                                                    <SvgMyanmarFlag width={35} height={35} />}
                                            </span>
                                        </Flex>
                                    </Col>
                                    {
                                        openSuccessPage ? <>
                                            <Image preview={false} src={successRegisterImage}
                                                alt={"Register Success Image"} />
                                            <Title level={3}>
                                                Registration Received – Thank You!
                                            </Title>
                                            <Text>
                                                Thank you for completing your registration with Ninja Van Myanmar! We've received your request and will review it shortly. We'll be in touch soon to confirm approval.<br />
                                                Wishing you a wonderful day ahead!
                                            </Text>
                                        </> :
                                            <Col lg={14} md={20} sm={24} xs={24}>
                                                {
                                                    !openForgotPasswordPage &&
                                                    <ConfigProvider theme={{
                                                        components: {
                                                            Segmented: {
                                                                itemSelectedBg: Theme.colors.primary,
                                                                itemSelectedColor: Theme.colors.white,
                                                                trackBg: Theme.colors.light_primary,
                                                                itemHoverBg: Theme.colors.light_primary,
                                                                itemActiveBg: Theme.colors.light_primary
                                                            }
                                                        }
                                                    }}>
                                                        <Segmented
                                                            block
                                                            style={{ background: Theme.colors.background }}
                                                            options={[
                                                                {
                                                                    label: <span>{i18next.t('login')}</span>,
                                                                    value: 'LOGIN'
                                                                },
                                                                {
                                                                    label: <span>{i18next.t('register')}</span>,
                                                                    value: 'REGISTER'
                                                                }]}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    tabValue: value
                                                                })
                                                            }}
                                                        />
                                                    </ConfigProvider>
                                                }
                                                {tabValue === 'LOGIN' && !openForgotPasswordPage && <p>{i18next.t("login_page_text")}</p>}
                                            </Col>
                                    }
                                    {
                                        openForgotPasswordPage ? <ForgotPassword setInitial={this.handleInitialState} /> : <></>
                                    }
                                </Row>
                                {
                                    tabValue === 'LOGIN' && !openSuccessPage && !openForgotPasswordPage ? <Login openForgotPasswordPage={this.handleForgotPage} /> :
                                        tabValue === 'REGISTER' && !openSuccessPage ?
                                            <Register openSuccessPage={this.handleSuccessPage} setInitial={this.handleInitialState} /> : <></>
                                }
                            </Col>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <span style={{
                                    color: '#212121', fontSize: 12
                                }}>{`© Copyright Ninja Van Myanmar ${currentYear}. All rights reserved.`}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ConfigProvider>
        </>)
    }
}

export default compose(withRouter)(LandingPage)