import { PlusCircleFilled } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Col, Row } from "antd";
import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { MainTitle, NJVButton, NJVTabs } from "../../components/core-component";
import Theme from "../../components/theme";
import withRouter from "../../network/with-router";
import { updateStatusKey } from "../../redux/profile-slice";
import CustomPath from "../../routes/custom-path";
import LocationList from "./location-list";

class UserAddressPage extends React.Component {
    onChange = (key) => {
        if (key === '1') {
            this.props.dispatch(updateStatusKey("myLocation"))
        } else if (key === '2') {
            this.props.dispatch(updateStatusKey("royalCustomer"))
        }
    }

    render() {
        const items = [
            {
                key: '1',
                label: <span style={{ fontSize: 18 }}>{i18next.t("my_address")}</span>,
                children: <LocationList status="myLocation" />,
            },
            {
                key: '2',
                label: <span style={{ fontSize: 18 }}>{i18next.t("loyal_customer")}</span>,
                children: <LocationList status="royalCustomer" />,
            },
        ];

        return (
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <MainTitle label={i18next.t("fav_location")} />
                </Col>
                <Col span={12} style={{ textAlign: "end" }}>
                    <Link to={CustomPath.user_address_create}>
                        <NJVButton
                            type="primary"
                            icon={<PlusCircleFilled />}
                            buttonprimary={Theme.colors.primary}
                        >
                            {
                                this.props.isMobileSize ? '' : i18next.t("create_new_location")
                            }
                        </NJVButton>
                    </Link>
                </Col>
                <Col span={24}>
                    <NJVTabs style={{ background: '#fff', borderRadius: 12, padding: 15, }} defaultActiveKey={`${this.props?.location.state === "myLocation" ? '1' : this.props?.location.state === "royalCustomer" ? '2' : '1'}`} items={items} onChange={this.onChange} />
                </Col>
            </Row>
        )
    }
}
export default compose(withRouter)(UserAddressPage);