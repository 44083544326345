import { compose } from "@reduxjs/toolkit";
import { ConfigProvider, message } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import withRouter from "../network/with-router";

class PublicBlankApp extends React.Component {

    handleRedirect = () => {
        try {
            const appLink = "nvmshipper://open?showStartAds=false&isCheckPaymentReceived=true&paymentType=WAVE_PAY";
            window.location.href = appLink;
        } catch (e) {
            message.error(e)
        }
    }
    render() {
        return (
            <ConfigProvider theme={{
                token: {
                    fontFamily: "Radio Canada"
                },
                components: {
                    Layout: {
                        bodyBg: "#ffffff",
                    },
                    Menu: {
                        itemBorderRadius: 0,
                        itemSelectedBg: '#fff',
                        itemSelectedColor: '#c2002f'
                    },
                    Button: {
                        primaryShadow: 'none',
                        defaultShadow: 'none',
                    },
                }
            }}>
                <Outlet />
            </ConfigProvider>

        )
    }
}
export default compose(withRouter)(PublicBlankApp)