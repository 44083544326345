import { FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Form, Image, message, Row, Slider, Typography } from 'antd'
import i18next from 'i18next'
import React, { Component, useState } from 'react'
import BadFeedback from '../../asset/bad-feedback-icon.png'
import GoodFeedback from '../../asset/good-feedback-icon.png'
import NormalFeedback from '../../asset/normal-feedback-icon.png'
import SurveySuccess from '../../asset/success-img.png'
import SurveyFeedbackBg from '../../asset/survey-feedback-bg.png'
import { NJVButton, NJVForm, NJVInput, NJVSelect } from '../../components/core-component'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import IMAGE_URL from '../../network/imageUrl'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'

const divisionList = [
    {
        label: 'ရန်ကုန်မြို့',
        value: 'ရန်ကုန်မြို့'
    },
    {
        label: 'မန္တလေးမြို့',
        value: 'မန္တလေးမြို့'
    },
    {
        label: 'အခြားမြို့',
        value: 'အခြားမြို့'
    }
]

const IconSlider = (props) => {
    const { value: formValue, onChange: formOnChange } = props;
    const [value, setValue] = useState(formValue || 0);

    const getTooltip = (value) => {
        if (value <= 6) {
            return (
                <Flex align='center' vertical>
                    <Image src={BadFeedback} alt="Bad Feedback" preview={false} style={{ width: 30, height: 30 }} />
                    <span>ဝန်ဆောင်မှုမကောင်းပါ</span>
                </Flex>
            );
        } else if (value === 7 || value === 8) {
            return (
                <Flex align='center' vertical>
                    <Image src={NormalFeedback} alt="Bad Feedback" preview={false} style={{ width: 30, height: 30 }} />
                    <span>မှတ်ချက်မပေးလိုပါ</span>
                </Flex>
            );
        } else {
            return (
                <Flex align='center' vertical>
                    <Image src={GoodFeedback} alt="Bad Feedback" preview={false} style={{ width: 30, height: 30 }} />
                    <span>စိတ်ကျေနပ်မှုရှိပါတယ်</span>
                </Flex>
            );
        }
    }

    const colors = {
        red: '#808080',
        yellow: '#F7B935',
        green: '#10C200',
    };

    const marks = {
        1: { style: { color: colors.red }, label: <strong>1</strong> },
        2: { style: { color: colors.red }, label: <strong>2</strong> },
        3: { style: { color: colors.red }, label: <strong>3</strong> },
        4: { style: { color: colors.red }, label: <strong>4</strong> },
        5: { style: { color: colors.red }, label: <strong>5</strong> },
        6: { style: { color: colors.red }, label: <strong>6</strong> },
        7: { style: { color: colors.yellow }, label: <strong>7</strong> },
        8: { style: { color: colors.yellow }, label: <strong>8</strong> },
        9: { style: { color: colors.green }, label: <strong>9</strong> },
        10: { style: { color: colors.green }, label: <strong>10</strong> },
    };

    const handleChange = (value) => {
        setValue(value);
        if (formOnChange) {
            const transformedValue = value === 0 ? null : value;
            formOnChange(transformedValue);
        }
    }

    return (
        <div className="icon-wrapper">
            <FrownOutlined className={'prev-icon-wrapper'} />
            <Slider {...props} onChange={handleChange} size="large" tooltip={{
                formatter: (value) => getTooltip(value)
            }} marks={marks} />
            <SmileOutlined className={'next-icon-wrapper'} />
        </div>
    );
};

class NpsForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            openSuccessPage: false,
        }
        this.formRef = React.createRef();
    }
    componentDidMount() {
        const { search } = this.props.location;
        const queryParams = new URLSearchParams(search);
        const uniqueId = queryParams.get("uniqueId");
        if (uniqueId) {
            this.formRef?.current?.setFieldsValue({ 'businessName': uniqueId })
        }
        console.log(uniqueId)
    }

    onFinish = (values) => {
        this.setState({ loading: true });
        ApiHandlerNoAuth({ url: Api.survey, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData: values, disableShowMessage: true })
            .then(() => {
                this.setState({ openSuccessPage: true });
            }).catch((error) => { })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    render() {
        const { loading, openSuccessPage } = this.state;

        return (
            <>

                {
                    openSuccessPage ? (
                        <Row style={{ height: '100vh', alignItems: 'center' }}>
                            <Col lg={6} md={5} sm={1} xs={1} />
                            <Col lg={12} md={14} sm={22} xs={22}>
                                <Flex vertical gap={12} align='center'>
                                    <Image width={100} preview={false} src={SurveySuccess} alt="Feedback Success" />
                                    <Typography.Text style={{ textAlign: 'center' }}>အချိန်ပေးပြီးဖြေကြားပေးတဲ့အတွက်ကျေးဇူးအများကြီးတင်ရှိပါတယ်။ ပျော်‌ရွှင်ဖွယ်ကောင်းသောနေ့လေးဖြစ်ပါစေ 🥰</Typography.Text>
                                </Flex>
                            </Col>
                        </Row>
                    ) :
                        <Row>
                            <Col lg={6} md={5} sm={1} xs={1} />
                            <Col lg={12} md={14} sm={22} xs={22}>
                                <Image src={IMAGE_URL.nps} alt="Survey Bg" preview={false} style={{ marginTop: 10 }} />
                                <Typography.Title level={3} style={{ marginBottom: 20 }}>Customer Feedback Form</Typography.Title>
                                <Typography.Title level={5} style={{ fontWeight: 500 }}>
                                    Ninja Van ၏ ဝန်ဆောင်မှုအရည်အသွေး ပိုမိုကောင်းမွန်ရန်အတွက် လူကြီးမင်းတို့၏အပြုသဘောဆောင်သော အကြံပြုချက်များအတွက် ကျေးဇူးအထူးတင်ရှိပါသည်။
                                </Typography.Title>
                                <NJVForm ref={this.formRef} labelpadding="0 0 12px" layout='vertical' style={{ marginTop: 30 }} onFinish={this.onFinish} onFinishFailed={() => {
                                    message.destroy()
                                    message.error('Please fill required fields');
                                }}>
                                    <Row gutter={[4, 4]}>
                                        <Col span={24}>
                                            <Form.Item name={'businessName'} label={`Company Name/ Online Shop Name/ APP ID`}
                                                rules={[{ required: true, message: '*(requried)' }]}
                                            >
                                                <NJVInput size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={'pickupRegion'} label={`ပစ္စည်းကောက်ခံပေးရသည့်နေရာ`}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                <NJVSelect selectoptionpadding={`6px 12px`} size="large" options={divisionList} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label={`၀န်ဆောင်မှုနှင့်ပတ်သက်ပြီး Rating ပေးရမည့်နမူနာပုံစံ`}>
                                                <Image src={SurveyFeedbackBg} alt="Survey Feedback Bg" preview={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="pickupServiceRating" label={`ပစ္စည်းလာရောက်ကောက်ယူသော ဝန်ဆောင်မှုအပေါ်တွင် စိတ်ကျေနပ်မှု`}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                {/* <NJVRadioGroup style={{ width: '100%' }}>
                            <Row>
                              <Col span={2}></Col>
                              {
                                options.map((option, index) => (
                                  <Col span={2} key={index}>
                                    <div style={{ marginBottom: 5 }}>
                                      {option.value}
                                    </div>
                                    <Radio value={option.value} style={{ marginInlineEnd: 0 }}></Radio>
                                  </Col>
                                ))
                              }
                            </Row>
                          </NJVRadioGroup> */}
                                                <IconSlider min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="customerServiceRating" label={`Customer Service ၏ဝန်ဆောင်မှုအပေါ်တွင် စိတ်ကျေနပ်မှု`}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                <IconSlider min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="deliveryServiceRating" label={`ပစ္စည်းပို့ဆောင်သောရက်ကြာချိန်အား စိတ်ကျေနပ်မှု`}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                <IconSlider min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="invoiceServiceRating" label={`ငွေစာရင်းရှင်းခြင်းအပေါ်တွင် စိတ်ကျေနပ်မှု`}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                <IconSlider min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="recommentingToAntherRating" label={`Ninja Van နှင့်လက်တွဲလုပ်ကိုင်ရန်အတွက် အခြားအသိမိတ်ဆွေများကို အကြံပြုဖြစ်ပါတယ် `}
                                                rules={[{ required: true, message: '*(requried)' }]}>
                                                <IconSlider min={0} max={10} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={'additionalComment'} label={`အခြားအကြံပြုချက်များ`}>
                                                <NJVInput istextareainput="true" showCount maxLength={300} size="large" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item style={{ textAlign: 'center' }}>
                                                <NJVButton block
                                                    loading={loading}
                                                    htmlType="submit" size="large" type="primary" style={{ background: Theme.colors.primary }}>
                                                    {i18next.t('submit')}
                                                </NJVButton>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </NJVForm>
                            </Col>
                        </Row>
                }
            </>
        )
    }
}

export default compose(withRouter)(NpsForm)