import { compose } from "@reduxjs/toolkit";
import { Col, Flex, message, Row } from "antd";
import Lottie from "lottie-react";
import React from "react";
import SuccessAnimation from '../../animate-lottie/lottie-payment-success.json';
import { NJVButton } from "../../components/core-component";
import Theme from "../../components/theme";
import withRouter from '../../network/with-router';
class KBZReturnPage extends React.Component {
    handleRedirect = () => {
        try {
            const appLink = "nvmshipper://open?showStartAds=false&isCheckPaymentReceived=true&paymentType=KBZ_PAY";
            window.location.href = appLink;
        } catch (e) {
            message.error(e)
        }
    }
    render() {
        return (

            <Flex justify="center" >
                <Row>
                    <Col span={2} />
                    <Col span={20}>
                        <Row>
                            <Col span={24}>
                                <Lottie
                                    style={{ height: '300px' }}
                                    animationData={SuccessAnimation}
                                    loop={false}
                                    autoplay={true}
                                />
                            </Col>
                            <Col span={24}>

                                <div style={{ textAlign: 'center', marginTop: -40 }}>
                                    <span style={{ fontSize: 21, fontWeight: '700', color: Theme.colors.success }}>Payment Completed</span>
                                    <br />
                                    <br />
                                    <span style={{ fontSize: 16, color: 'gray', fontWeight: '600' }}>
                                        We confirm that your online payment was successful, and your transaction has been logged securely for future reference.
                                    </span>
                                </div>

                            </Col>
                            <Col span={24} style={{ marginTop: 30 }}>
                                <NJVButton buttonprimary={Theme.colors.primary} type="primary" size="large" shape="round" style={{ width: '100%' }}
                                    onClick={() => this.handleRedirect()}
                                >
                                    Go To Ninja Van Myanmar
                                </NJVButton>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Flex>
        )
    }
}
export default compose(withRouter)(KBZReturnPage)