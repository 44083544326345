
const shipper = '/v1'
const CustomPath = {
    app: '/',

    dashboard: shipper + '/dashboard',
    profile: shipper + '/profile',
    changePassword: shipper + '/changePassword',

    notification: shipper + '/notification',
    notification_detail: shipper + '/notification/detail',

    order: shipper + '/parcel',
    order_create: shipper + '/parcel-create',
    test_parcel_create: shipper + '/test-parcel-create',
    order_return: shipper + '/parcel-return',
    order_update: shipper + '/parcel-update',
    order_history: shipper + '/parcel-history',
    order_detail: shipper + '/parcel-detail',
    order_detail_param: shipper + '/parcel-detail/:id',
    public_parcel_detail: '/public/tracking',
    pickup_call: shipper + '/order/add-order-data-to-sheet',
    order_create_fail_log: shipper + '/order/fail-logs',

    order_awb: shipper + '/awb',
    rom_order: shipper + '/rom',
    rts_order: shipper + '/rts-order',
    rts_order_detail: shipper + '/rts-order/detial',

    user_address: shipper + '/address',
    user_address_create: shipper + '/address/create',
    user_address_update: shipper + '/address/update',
    user_address_update_param: shipper + '/address/update/:id',

    nearby_ninjas: shipper + '/nearby-ninjas',

    airwaybill: shipper + '/air-way-bill',


    terms_and_condition: shipper + '/terms-and-condition',
    privacy_and_policy: shipper + '/privacy-and-policy',
    privacy_and_policy_public: '/public/privacy-and-policy',
    privacy_and_policy_public_driver: '/public/driver/privacy-and-policy',
    guide: shipper + '/guide',
    public_guide: '/public/guide',
    coverage: shipper + '/coverage',

    store: shipper + '/store',
    add_product: shipper + '/add-product',
    update_product: shipper + '/update-product',
    products: '/public/store',
    products_param: '/public/store/:uniqueId',
    cart_items_list: '/public/store/:uniqueId/cart-items',
    product_detail: '/public/store/:uniqueId/product-detail',

    single_order_result: shipper + '/single-order-detail',
    single_order_result_param: shipper + '/single-order-detail/:id',

    quick_voucher_customer_confirm: '/public/customer_voucher_confirm',
    quick_voucher_customer_confirm_param: '/public/customer_voucher_confirm/:voucherId',
    confirm_order: shipper + '/order/confirm-order',
    confirm_order_create: '/order/confirm-order/create',
    confirm_order_update: '/order/confirm-order/update',
    confirm_order_detail: '/order/confirm-order/detail',

    feedback: shipper + '/feedback',

    faq: shipper + '/faq',

    invoice: shipper + '/invoice',

    login: '/',
    logout: "/logout",
    signUp: "/signUp",
    forgot_password: "/forgot-password",
    delete_account: "/delete-account",

    payment_history: shipper + "/payment-history",
    payment_info: shipper + "/payment-info",

    printer_setting: shipper + '/printer-setting',

    loyalty: shipper + '/ninja-rewards',
    loyalty_shop_detail: shipper + '/ninja-rewards/shop-detail',
    loyalty_shop_detail_params: shipper + '/ninja-rewards/shop-detail/:id',
    shops: shipper + '/ninja-rewards/shops',
    point: shipper + '/point',
    point_history: shipper + '/point/history',
    loyalty_faq: shipper + '/ninja-rewards/faq',

    ticket_repair_request: '/it-repair-request',
    ticket_repair_request_form: '/it-repair-request/form',
    ticket_repair_search: '/it-repair-request/search',

    nps_form: '/public/customer-feedback',
    public_job: '/career',
    public_job_detail: '/career/detail',
    public_job_detail_params: '/career/detail/:id',
    public_job_form: '/career/apply',
    public_job_form_params: '/career/apply/:id',
    public_kbz_referer_page: '/kbz/request',
    public_kbz_return_page: '/kbz/payment/success',
    public_wave_pay_success_page: '/wavepay/payment/success',
    public_invoice: '/public/invoice',
    public_token_checker: '/campaign',
    public_token_list: '/campaign-list'
};

export default CustomPath



