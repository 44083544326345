import { compose } from '@reduxjs/toolkit'
import { Col, ConfigProvider, Flex, Image, List, Row, Tag } from 'antd'
import React, { Component } from 'react'
import Logo from '../../asset/NinjaLogo.png'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import { NJVInput } from '../../components/core-component'

class PublicTokenList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      filterData: [],
      isFilter: false
    }
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);
    const searchValue = params.get("uniqueId");
    this.fetchData(searchValue);
  }

  fetchData = (value) => {

    this.setState({
      isLoading: true
    })
    const requestParams = {
      'uniqueId': value
    }
    ApiHandlerNoAuth({ url: Api.public_token_checker, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestParams, disableShowMessage: true })
      .then((response) => {
        this.setState({
          data: response.data
        })
      }).catch(() => { })
      .finally(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  handleFilterChange = (e) => {
    const { data } = this.state
    const value = e.target.value;
    const filteredList = data.filter((item) =>
      item.token?.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filterData: filteredList,
      isFilter: true
    })
  };

  render() {
    const { data, isLoading, isFilter, filterData } = this.state;

    return (
      <>
        <div style={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          zIndex: 2,
          background: '#fff'
        }}>
          <Row>
            <Col span={1}></Col>
            <Col span={22}>
              <Image
                width={140}
                src={Logo}
                preview={false}
              />
            </Col>
          </Row>
        </div>
        <Row style={{ marginTop: 60 }}>
          <Col lg={4} md={4} sm={1} xs={1} />
          <Col lg={16} md={16} sm={22} xs={22}>
            <Flex justify='space-between' style={{ marginBottom: 20 }}>
              <NJVInput
                onChange={this.handleFilterChange}
                style={{ width: 150, background: '#f1f1f1' }}
                placeholder="Search by token"
              />
              <span style={{ fontWeight: 500, fontSize: 16 }}>
                Total Count
                <ConfigProvider
                  theme={{
                    token: {
                      fontSize: 16
                    }
                  }}>
                  <Tag color='green' style={{ marginLeft: 5 }}>{data.length}</Tag>
                </ConfigProvider>
              </span>
            </Flex>
            <Flex justify='space-between' style={{ padding: '10px 16px', background: '#eee' }}>
              <span style={{ marginLeft: 10 }}>Token</span>
              <span style={{ marginRight: 40 }}>Tracking Number</span>
            </Flex>
            <div
              className='mobile-scrollbar'
              style={{
                height: '76vh',
                overflow: 'auto',
                padding: '0 16px',
              }}
            >
              <List
                dataSource={isFilter ? filterData : data}
                loading={isLoading}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <span>{item.token}</span>
                    <span>{item.trackingNumber}</span>
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(PublicTokenList)