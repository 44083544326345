const prefix = "https://ninjavanstorages.s3.ap-southeast-1.amazonaws.com/portal-asset/shipper/"

const IMAGE_URL = {
    nps: prefix + "nps.jpg",
    landing_img: prefix + "landing.png",
    kbz_img_one: prefix + "quick_pay_guide_one.png",
    kbz_img_two: prefix + "quick_pay_guide_two.png",
    kbz_img_three: prefix + "quick_pay_guide_three.png"
}

export default IMAGE_URL;