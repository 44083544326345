import {
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Checkbox, Col, Flex, Form, message, Modal, Row, Space, Table } from 'antd';
import i18next from 'i18next';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NJVButton, NJVCheckbox, NJVInput, NJVTable } from '../../components/core-component';
import Theme from '../../components/theme';
import withRouter from '../../network/with-router';
import InsuranceTermsAndCondition from '../terms-and-condition/insurance-terms-and-condition';

class InsuranceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowButton: false,
      isShowAgreeButton: false,
      isRuleModalOpen: false,
      isInsuranceModal: false,
      page: 1,
      insuranceInfo: {
        productItems: this.props.insuranceData?.insuranceItems || [],
      },
      totalPrice: this.props.insuranceData?.totalPrice || 0,
      totalQuantity: this.props.insuranceData?.totalQuantity || 0,
      totalInsurance: this.props.insuranceData?.insuranceFees || 0,
      isInsuranceChecked: this.props.isInsurance || false,
    }
    this.formRef = React.createRef();
    this.productFormRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (((prevProps.receiverData === this.props.receiverData)) || prevProps.senderData === this.props.senderData) {
      const newValues = this.getInitialValues();
      this.formRef?.current?.setFieldsValue(newValues)

      if (prevProps.insuranceData !== this.props.insuranceData) {
        this.setState({
          insuranceInfo: {
            productItems: this.props.insuranceData?.insuranceItems || [],
          },
          totalPrice: this.props.insuranceData?.totalPrice,
          totalQuantity: this.props.insuranceData?.totalQuantity,
          totalInsurance: this.props.insuranceData?.insuranceFees,
        });
      }
    }

    if (prevProps.isInsurance !== this.props.isInsurance) {
      this.setState({
        isInsuranceChecked: this.props.isInsurance
      })
    }

    if (prevProps.removeStatus !== this.props.removeStatus) {
      this.setState({
        insuranceInfo: null,
        isShowButton: false,
        isShowAgreeButton: false,
      })
      this.props.handleInsuranceCheckbox(false)
      this.props.handleInsuranceData(null)
      this.props.handleRemoveStatus(false)
    }
  }

  getInitialValues = () => {
    const { senderData, receiverData } = this.props;
    return {
      senderName: senderData?.fullName,
      senderAddress: senderData?.address1,
      senderTownshipDivision: `${senderData?.townshipName}/${senderData?.township?.division?.name}`,
      receiverName: receiverData?.favourite ? receiverData?.favouriteLocationId?.name : receiverData?.receiverName,
      receiverAddress: receiverData?.favourite ? receiverData?.favouriteLocationId?.address1 : receiverData?.receiverAddress,
      receiverTownshipDivision: receiverData?.favourite ? `${receiverData?.favouriteLocationId?.township?.division.name}/${receiverData?.favouriteLocationId?.township?.name}` : `${receiverData?.townshipId?.divisionName}/${receiverData?.townshipId?.label}`
    }
  }

  handleInsuranceCheckbox = () => {
    this.setState({
      isInsuranceModal: true
    })
  }

  handleUserCheckbox = (value) => {
    this.setState({
      isShowButton: value,
      isRuleModalOpen: false,
      isShowAgreeButton: value
    })
  }

  handleProductSubmit = (event) => {
    event.preventDefault();
    this.productFormRef.current.submit();
  };

  handleActivity = (key, value) => {
    this.props.handleReceiverData({ [key]: value });
  }

  calculateTotalPrice = (data) => {
    return data.reduce((acc, item) => acc + parseFloat(item.price * item.quantity || 0), 0);
  };

  calculateTotalQuantity = (data) => {
    return data.reduce((acc, item) => acc + +item.quantity, 0);
  }

  calculateInsuranceFees = (data) => {
    return Math.ceil(parseInt(data) * 0.0125);
  };

  editProduct = (uid) => {
    const { insuranceInfo } = this.state
    const editProduct = insuranceInfo?.productItems.find(item => item.key === uid);
    if (editProduct) {
      this.productFormRef.current.setFieldsValue({ key: uid, ...editProduct });
    }
    this.setState({
      isUpdateProduct: true
    })
  }

  deleteProduct = (uid) => {
    const { insuranceInfo, page, isUpdateProduct } = this.state
    if (isUpdateProduct) {
      message.destroy()
      message.error("You can't delete the receiver while editing their information.")
      return
    }
    const new_ProductItems = insuranceInfo?.productItems.filter(item => item.key !== uid)
    const newPage = new_ProductItems.length === 0 ? 1 : (Math.ceil(new_ProductItems.length / 10) >= page ? page : page - 1);
    const totalPrice = this.calculateTotalPrice(new_ProductItems)
    const totalQuantity = this.calculateTotalQuantity(new_ProductItems)
    const totalInsurance = this.calculateInsuranceFees(totalPrice)

    this.setState({
      insuranceInfo: {
        ...insuranceInfo,
        productItems: new_ProductItems,
      },
      totalPrice,
      totalQuantity,
      totalInsurance,
      page: newPage
    })
  }

  onProductFinish = (values) => {

    const { insuranceInfo, isUpdateProduct } = this.state;
    const uid = uuidv4()

    let isUpdate = false;
    let currentUserInput = { ...values }

    if (!values?.key) {
      currentUserInput = { ...currentUserInput, key: uid }
    } else {
      isUpdate = true
    }
    let product_collection = []
    if (isUpdate) {
      product_collection = insuranceInfo?.productItems?.map(info => {
        return info?.key === currentUserInput?.key ? currentUserInput : info
      })
    } else {
      product_collection = [...(insuranceInfo?.productItems || []), currentUserInput]
    }

    const totalPrice = this.calculateTotalPrice(product_collection)
    const totalQuantity = this.calculateTotalQuantity(product_collection)
    const totalInsurance = this.calculateInsuranceFees(totalPrice)

    if (totalPrice > 1000000) {
      message.destroy()
      message.error(i18next.t('limit_price'))
      return
    }

    this.setState((prevState) => ({
      isUpdateProduct: false,
      insuranceInfo: {
        ...prevState.insuranceInfo,
        productItems: product_collection
      },
      totalPrice,
      totalQuantity,
      totalInsurance
    }))

    message.success('product successfully ' + (isUpdateProduct ? 'updated' : 'added'));

    this.productFormRef.current.resetFields();
  }

  handleCancelInsurance = () => {
    this.setState({
      isInsuranceModal: false,
      isShowButton: false,
      insuranceInfo: null,
      isShowAgreeButton: false
    })
    this.props.handleInsuranceCheckbox(false)
    this.props.handleInsuranceData(null)
    this.productFormRef.current.resetFields()
  }

  onFinish = () => {
    const { insuranceInfo, totalInsurance, totalPrice, totalQuantity } = this.state;

    if (!totalInsurance || totalInsurance === 0) {
      message.error("Please Enter at least one insurance item.")
    }

    this.props.handleInsuranceData({
      insuranceFees: totalInsurance,
      totalPrice,
      totalQuantity,
      insuranceItems: insuranceInfo.productItems,
      isExistInsurance: true
    })
    this.setState({
      isInsuranceModal: false,
    })
    this.props.handleInsuranceCheckbox(true)
  }

  render() {
    const { isShowButton, page, isUpdateProduct, insuranceInfo, isInsuranceChecked, isInsuranceModal, isRuleModalOpen, isShowAgreeButton, totalPrice, totalQuantity, totalInsurance } = this.state;
    const { receiverData } = this.props;

    const initialValues = this.getInitialValues();

    const formattedTotalPrice = new Intl.NumberFormat().format(totalPrice)

    let columns = [
      {
        title: <>{i18next.t("no")}</>,
        key: 'index',
        render: (value, item, index) => {
          return <span>{(page - 1) * 10 + index + 1}</span>
        },
        width: 5,
        responsive: ['md'],
      },
      {
        title: <>{i18next.t("name")}</>,
        dataIndex: 'name',
        key: 'name',
        align: 'center'
      },
      {
        title: <>{i18next.t("price")}</>,
        dataIndex: 'price',
        key: 'price',
        align: 'center'
      },
      {
        title: <>{i18next.t("quantity")}</>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center'
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, product) => (
          <Flex vertical align="center" gap={5}>
            <NJVButton size={'middle'} type="primary" shape="circle"
              onClick={() => this.editProduct(product?.key)}
            ><EditOutlined /></NJVButton>
            <NJVButton size={'middle'} type="primary" shape="circle"
              onClick={() => this.deleteProduct(product?.key)}
              danger><DeleteOutlined /></NJVButton>
          </Flex>
        ),
        width: 10,
      },
    ]

    return (
      <>
        <Modal open={isRuleModalOpen} onCancel={() => this.setState({ isRuleModalOpen: false })} title={i18next.t("terms_and_condition")} footer={null}>
          <InsuranceTermsAndCondition agreeButton={isShowAgreeButton} handleCheckbox={this.handleUserCheckbox} />
        </Modal>
        <Modal
          title={i18next.t("purchase_insurance")}
          open={isInsuranceModal} maskClosable={false}
          onCancel={() => this.setState({ isInsuranceModal: false })}
          footer={null}>
          <Row>
            <Form
              name='basic'
              ref={this.formRef}
              onFinish={this.onFinish}
              initialValues={
                initialValues
              }
              layout='vertical'
              autoComplete='off'>
              <Col span={24}>
                <h4>{i18next.t("sender_info")}</h4>
                <Row gutter={[16, 0]} style={{ marginBlock: 15 }}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item name="senderName" label={i18next.t("name")} rules={[
                      {
                        required: true,
                        message: i18next.t('err_msg_enter_sender_name')
                      }
                    ]}>
                      <NJVInput size="large" bgcolor={Theme.colors.secondary} disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item name="senderTownshipDivision" label={i18next.t("township_division")} rules={[
                      {
                        required: true
                      }
                    ]}>
                      <NJVInput size="large" bgcolor={Theme.colors.secondary} disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item name="senderAddress" label={i18next.t("address")} rules={[
                      {
                        required: true,
                        message: i18next.t('err_msg_enter_address')
                      }
                    ]}>
                      <NJVInput showCount maxLength={180} istextareainput="true" size="large" bgcolor={Theme.colors.secondary} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <h4>{i18next.t("receiver_info")}</h4>
                <Row gutter={[16, 0]}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item name="receiverName" label={i18next.t("name")} rules={[
                      {
                        required: true,
                        message: i18next.t('err_msg_enter_receiver_name')
                      }
                    ]}>
                      <NJVInput size="large" bgcolor={Theme.colors.secondary} onChange={(e) => this.handleActivity('receiverName', e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item name="receiverTownshipDivision" label={i18next.t("township_division")} rules={[
                      {
                        required: true
                      }
                    ]}>
                      <NJVInput size="large" bgcolor={Theme.colors.secondary} disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item name="receiverAddress" label={i18next.t("address")} rules={[
                      {
                        required: true,
                        message: i18next.t("err_msg_enter_address")
                      }
                    ]}>
                      <NJVInput showCount maxLength={180} istextareainput="true" size="large" bgcolor={Theme.colors.secondary} onChange={(e) => this.handleActivity('address', e.target.value)} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <h4>{i18next.t("parcel_detail")}</h4>
                <Col span={24}>
                  <NJVTable 
                  headerbgcolor="#F7F7F7"
                  headerborderradius='0px'
                  cellblock="5px"
                  cellinline="3px"
                  tableborder="#EAEAEA"
                  columns={columns} dataSource={insuranceInfo?.productItems} onChange={this.onChangePagination} />
                </Col>
                {
                  insuranceInfo?.productItems?.length > 0 && (
                    <Col span={24}>
                      <Form.Item>
                        <Flex gap={60} style={{ fontSize: 17, fontWeight: 500 }}>
                          <span>
                            {i18next.t("parcel_value")}-  {formattedTotalPrice}
                          </span>
                          <span>
                            {i18next.t("total_quantity")}-  {totalQuantity}
                          </span>
                        </Flex>
                      </Form.Item>
                    </Col>
                  )
                }
                <Form
                  name='productForm'
                  style={{ marginTop: 15 }}
                  ref={this.productFormRef}
                  onFinish={this.onProductFinish}
                  layout='vertical'>
                  <Row gutter={24}>
                    <Form.Item name="key" hidden>
                      <NJVInput />
                    </Form.Item>
                    <Col lg={8} md={8} sm={24} xs={24} style={{ textAlign: 'start' }}>
                      <Form.Item name="name" label={i18next.t("name")} rules={[
                        {
                          required: true,
                          message: i18next.t('enter_name')
                        },
                        {
                          max: 50,
                          message: i18next.t('Name cannot exceed 50 characters.'),
                        }
                      ]}>
                        <NJVInput bgcolor={Theme.colors.secondary} size="large" />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item name="price" label={i18next.t("price")} rules={[
                        {
                          required: true,
                          message: i18next.t('error_msg_price'),
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: i18next.t('Invalid Amount'),
                        },
                        {
                          max: 7,
                          message: i18next.t('Price cannot exceed 7.'),
                        }
                      ]}>
                        <NJVInput bgcolor={Theme.colors.secondary} size="large" min={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                      <Form.Item name="quantity" label={i18next.t("quantity")} rules={[
                        {
                          required: true,
                          message: i18next.t('error_msg_quantity')
                        },
                        {
                          pattern: /^[1-9][0-9]*$/,
                          message: i18next.t('Quantity must be a positive integer and must start from 1'),
                        },
                        {
                          max: 7,
                          message: i18next.t('Quantity cannot exceed 7.'),
                        }
                      ]}>
                        <NJVInput bgcolor={Theme.colors.secondary} size="large" min={1} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                      <Form.Item>
                        <NJVButton type="primary" danger style={{ marginRight: 10 }} onClick={() => this.productFormRef.current.resetFields()}>{i18next.t("clear")}</NJVButton>
                        <NJVButton type="primary" onClick={this.handleProductSubmit}>
                          {
                            isUpdateProduct ? <span>Update</span> : <span>{i18next.t("add_product")}</span>
                          }
                        </NJVButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col span={24}>
                <Form.Item name="insuranceFees">
                  <span style={{ fontWeight: 'bold', textAlign: 'left' }}>{i18next.t("insurance_fee")} - {`${insuranceInfo?.productItems ? totalInsurance : 0}`}  MMK</span>
                  <div style={{ color: Theme.colors.primary }}>
                    *{i18next.t("notice_insurance_1")} <br />
                    *{i18next.t("notice_insurance_2")}
                  </div>
                </Form.Item>
              </Col>

              <Checkbox checked={isShowButton} onClick={() => this.setState({ isRuleModalOpen: true })}>
                Agree with <span style={{ color: Theme.colors.primary, fontWeight: 'bold' }} >Term and Conditions</span>
              </Checkbox>
              <Col span={24}>
                <Row gutter={[16, 16]} style={{ marginBlock: 15, justifyContent: 'end' }}>
                  <NJVButton style={{ marginRight: 10 }} onClick={() => this.handleCancelInsurance()}>{i18next.t('insurance_cancel')}</NJVButton>
                  <NJVButton type="primary" disabled={!isShowButton} htmlType="submit" style={{ backgroundColor: `${isShowButton ? Theme.colors.primary : '#f5f5f5'}` }}>
                    <span>{i18next.t("insurance_purchase")}</span>
                  </NJVButton>
                </Row>
              </Col>
            </Form>
          </Row>
        </Modal>
        <NJVCheckbox primarycolor='#10C200'
          disabled={
            !(
              (receiverData?.favouriteLocationId) ||
              (
                receiverData?.receiverName &&
                receiverData?.receiverPhone &&
                receiverData?.townshipId &&
                receiverData?.receiverAddress
              )
            )
          }
          style={{ marginBlock: 24 }}
          checked={isInsuranceChecked}
          onChange={() => this.setState({ isInsuranceModal: true })}>{i18next.t("purchase_insurance")}
        </NJVCheckbox>
      </>
    )
  }
}

export default compose(withRouter)(InsuranceForm)