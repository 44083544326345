import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Form, Image, message, Modal, Row, Skeleton, Typography, Upload } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SurveySuccess from '../../asset/success-img.png'
import { ModalTitle, NJVButton, NJVForm, NJVInput } from '../../components/core-component'
import { SvgAttachFile, SvgDepartmentOutlined, SvgGenderOutlined, SvgJobLocation, SvgSalaryOutlined, SvgTrushFilled } from '../../components/custom-svgIcon'
import Theme from '../../components/theme'
import { checkValidFileSize } from '../../core/validator'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

class JobPostForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isSubmitting: false,
            data: null,
            cvFile: null,
            openSuccessModal: false,
            currentUserName: ''
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
        if (this.props.location.state?.scrollTop) {
            const element = document.getElementById('titleElement')
            element.scrollIntoView()
        }
    }

    fetchData = () => {

        this.setState({
            isLoading: true
        })
        ApiHandlerNoAuth({ url: Api.job_post_detail, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => {

            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleFileUpload = async (file) => {
        if (checkValidFileSize(file)) {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            formData.append('fileName', this.state.currentUserName);
            ApiHandlerNoAuth({ url: Api.job_post_upload, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, customMessage: 'Your CV has been uploaded successfully.' })
                .then((response) => {
                    this.setState({
                        cvFile: response.data
                    })
                    this.formRef.current.setFieldsValue({ cvFormUrl: response.data })
                }).catch((error) => {

                    if (error && error.response.status === 500) {
                        message.destroy()
                        message.error(error.response.data.message)
                    }
                    this.setState({
                        cvFile: null
                    })
                    this.formRef.current.setFieldsValue({ cvFormUrl: null });
                }).finally(() => {
                    this.setState({
                        isUploading: false,
                    })
                })
        }
    };

    saveForm = (values) => {

        const { data } = this.state;
        this.setState({
            isSubmitting: true
        })
        const requestData = {
            ...values,
            jobPostId: data.id
        }
        ApiHandlerNoAuth({ url: Api.job_post_submit, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData, disableShowMessage: true })
            .then(() => {
                this.setState({
                    openSuccessModal: true
                })
            }).catch((error) => {
            }).finally(() => {
                this.setState({
                    isSubmitting: false,
                })
            })
    }

    render() {
        const { data, cvFile, isUploading, isSubmitting, openSuccessModal, isLoading } = this.state;

        const getFileExtension = (fileName) => {
            return fileName?.split('.').pop();
        };

        const getDisplayText = (file) => {
            const extension = getFileExtension(file);

            switch (extension) {
                case 'png':
                case 'jpg':
                case 'jpeg':
                    return 'Image File';
                case 'pdf':
                    return 'PDF Document';
                case 'doc':
                case 'docx':
                    return 'Word Document';
                default:
                    return 'Unknown File Type';
            }
        };

        const uploadProps = {
            name: 'file',
            multiple: false,
            previceFile: true,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        return (
            <>
                <Modal
                    open={openSuccessModal}
                    centered
                    closable={false}
                    closeIcon={false}
                    footer={null}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col lg={6} md={5} sm={1} xs={1} />
                        <Col lg={12} md={14} sm={22} xs={22}>
                            <Flex vertical gap={12} align='center'>
                                <Image width={80} preview={false} src={SurveySuccess} alt="Form Submit Success" />
                                <Typography.Text style={{ textAlign: 'center' }}>{i18next.t('msg_successful_apply')}</Typography.Text>
                                <Link to={CustomPath.public_job}><NJVButton>Ok</NJVButton></Link>
                            </Flex>
                        </Col>
                    </Row>
                </Modal>
                <Row id='titleElement'>
                    <Col span={24} style={{ background: Theme.colors.white }}>
                        <Row style={{ paddingBottom: 20 }}>
                            <Col lg={3} md={1} xs={1} sm={1} />
                            <Col lg={18} md={22} xs={22} sm={22}>
                                {
                                    isLoading ? <Skeleton /> :
                                        <Flex vertical gap={10}>
                                            <Typography.Title level={4} style={{ color: '#515357', marginTop: 0 }}>{data?.position}</Typography.Title>
                                            <Flex vertical gap={10}>
                                                <Flex gap={10}>
                                                    <SvgDepartmentOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span style={{ color: '#808080', marginBlock: 0, fontSize: 17, fontWeight: 600 }}>{data?.department}</span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgJobLocation width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>{data?.location}</span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgGenderOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>
                                                        {data?.gender === 'Male' ? i18next.t('male') : data?.gender === 'Female' ? i18next.t('female') : data?.gender === 'Open To All' ? i18next.t('open_to_all') : ''}
                                                    </span>
                                                </Flex>
                                                <Flex gap={10}>
                                                    <SvgSalaryOutlined width={18} height={18} color={Theme.colors.primary} />
                                                    <span level={5} style={{ color: '#808080', marginBlock: 0, fontSize: 16, fontWeight: 500 }}>{data?.salary === 'Negotiable' ? i18next.t('negotiable') : data?.salary}</span>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{ background: Theme.colors.ant_bg_color, paddingTop: 20, paddingBottom: 30 }}>
                        <Row>
                            <Col lg={3} md={1} xs={1} sm={1} />
                            <Col lg={18} md={22} xs={22} sm={22}>
                                <Row>
                                    <Col span={24}>
                                        <ModalTitle label={i18next.t('submit_your_application')} />
                                    </Col>
                                    <Col lg={24} md={24} sm={24} xs={24} style={{ marginTop: 30 }}>
                                        <NJVForm
                                            name="wrap"
                                            labelCol={{
                                                flex: '130px',
                                            }}
                                            labelAlign="left"
                                            labelWrap
                                            wrapperCol={{
                                                flex: 1,
                                            }}
                                            colon={false}
                                            vertical
                                            style={{
                                                maxWidth: 600,
                                            }}
                                            onFinish={this.saveForm}
                                            ref={this.formRef}
                                        >
                                            <Form.Item name="name" label={<>{i18next.t('name')} <span style={{ color: 'red' }}>*</span></>}
                                                rules={[{
                                                    validator: (_, value) =>
                                                        value
                                                            ? Promise.resolve()
                                                            : Promise.reject(new Error("Name is required")),
                                                }]}
                                            >
                                                <NJVInput onChange={e => this.setState({ currentUserName: e.target.value })} />
                                            </Form.Item>
                                            <Form.Item
                                                name="phoneNumber"
                                                label={<>{i18next.t('phone_number')} <span style={{ color: 'red' }}>*</span></>}
                                                rules={[{
                                                    validator: (_, value) => {
                                                        const numberPattern = /^(09\d{7,9}|01\d{7})$/;
                                                        if (!numberPattern.test(value)) {
                                                            return Promise.reject('must start with "09" or "01"');
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                }]}>
                                                <NJVInput />
                                            </Form.Item>
                                            <Form.Item name="currentSalary" label={i18next.t('current_salary')}>
                                                <NJVInput />
                                            </Form.Item>
                                            <Form.Item name="expectedSalary" label={i18next.t('expected_salary')}>
                                                <NJVInput />
                                            </Form.Item>
                                            <Form.Item name="coverLetter" label="Cover Letter">
                                                <NJVInput istextareainput maxLength={200} showCount />
                                            </Form.Item>
                                            <Form.Item name="cvFormUrl" 
                                                label={<>CV Form <span style={{ color: 'red' }}>*</span></>}
                                                rules={[{
                                                    validator: (_, value) =>
                                                        value
                                                            ? Promise.resolve()
                                                            : Promise.reject(new Error("CV Form is required")),
                                                }]}
                                            >
                                                {
                                                    cvFile ?
                                                        <Flex justify='space-between' wrap="wrap" gap={10} align='center' style={{ padding: 10, background: '#fff', borderRadius: 10 }}>
                                                            <div
                                                                style={{
                                                                    maxWidth: '80%',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }}
                                                            >
                                                                {cvFile ? getDisplayText(cvFile) : 'No File Uploaded'}
                                                            </div>
                                                            <SvgTrushFilled style={{ cursor: 'pointer' }} width={15} height={15} color={Theme.colors.primary} onClick={() => {
                                                                this.setState({ cvFile: null });
                                                                this.formRef.current.setFieldsValue({ cvFormUrl: null })
                                                            }} />
                                                        </Flex> :
                                                        <Upload
                                                            {...uploadProps}
                                                            accept={["image/*", ".pdf", ".doc", ".docx"]}
                                                            maxCount={1}
                                                            showUploadList={false}
                                                        >
                                                            <NJVButton loading={isUploading} icon={<SvgAttachFile width={10} height={10} loading={isUploading} />}>{i18next.t('attach_cv')}</NJVButton>
                                                        </Upload>
                                                }
                                            </Form.Item>
                                            <Flex vertical style={{ fontSize: 12, marginBottom: 10, color: '#848484' }}>
                                                <span>Supported Format: .JPG, .JPEG, .PNG, .PDF, .DOC, .DOCX</span>
                                                <span>Maximum Size: 5MB</span>
                                            </Flex>
                                            <Form.Item style={{ textAlign: 'center' }}>
                                                <NJVButton disabled={isUploading} loading={isSubmitting} htmlType="submit" type="primary" buttonprimary={Theme.colors.primary}>{i18next.t('submit_application')}</NJVButton>
                                            </Form.Item>
                                        </NJVForm>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(JobPostForm)

