import { compose } from "@reduxjs/toolkit";
import { Card, Col, Divider, Flex, Row } from "antd";
import React from "react";
import { NJVButton } from "../../components/core-component";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { ApiHandlerNoAuth } from "../../network/network-manager";
import withRouter from '../../network/with-router';
import PaymentConfirmWaiting from '../../animate-lottie/lottie-payment-confirm-waiting.json';
import Lottie from "lottie-react";
class KBZRefererPage extends React.Component {

    constructor(props) {
        super()
        this.state = {
            kbz_order_info_url: null,
            kbz_prepay_id: null,
            kbz_payment_info: null,
            kbz_result_exists: false,
            opening_KBZ_Web: false
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href); // Get current URL
        const urlString = String(url)
        const urlKey = urlString.replace("https://www.ninjavan-mm.co/kbz/request?prepayId=", "");
        if (urlKey.startsWith('https://')) {
            this.setState({
                kbz_payment_info: {
                    order_info_url: urlKey
                },
                kbz_result_exists: true
            })
        } else {
            this.fetchKpayRedirectUrl(urlKey)
        }
    }

    goToKpayApplication = () => {
        this.setState({ opening_KBZ_Web: true })
        try {
            if (this.state.kbz_payment_info?.order_info_url) {
                window.open(this.state.kbz_payment_info?.order_info_url, '_blank')
            }
        } catch (e) { } finally { this.setState({ opening_KBZ_Web: false }) }
    }

    fetchKpayRedirectUrl = (paymentId) => {
        ApiHandlerNoAuth({ url: Api.payment_kbz_order_info, method: HTTP_METHOD.GET, specificId: paymentId })
            .then(response => {
                console.log(response)
                this.setState({ kbz_payment_info: response, kbz_result_exists: true })
            }
            )
            .catch(error => console.log(error))
            .finally(error => console.log(error));
    }

    render() {
        const { kbz_payment_info, kbz_result_exists, opening_KBZ_Web } = this.state
        return (
            <>
                <Row style={{ marginTop: 12 }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={PaymentConfirmWaiting}
                                    loop={false}
                                    autoplay={true}
                                />
                            </Col>
                            <Col span={24}>

                                <Card className="card-shadow" style={{ padding: 0 }}>
                                    <Row gutter={[16, 0]}>
                                        <Col span={24}>
                                            <Flex justify="space-between">
                                                <span>Total Amount</span>
                                                <span>{kbz_payment_info?.amount?.toLocaleString()} MMK</span>
                                            </Flex>
                                            <Divider style={{ margin: '15px 0px' }} />
                                        </Col>
                                        <Col span={24}>
                                            <Flex justify="space-between">
                                                <span>Payment Type</span>
                                                <span>{kbz_payment_info?.transaction_type}</span>
                                            </Flex>
                                            <Divider style={{ margin: '15px 0px' }} />
                                        </Col>
                                        <Col span={24}>
                                            <Flex justify="space-between">
                                                <span>Payment Method</span>
                                                <span>KBZPay</span>
                                            </Flex>
                                        </Col>
                                        <Col span={24} style={{ marginTop: 20 }}>
                                            <NJVButton buttonprimary={Theme.colors.primary} type="primary" size="large" shape="rounded" style={{ width: '100%' }}
                                                disabled={kbz_result_exists ? false : true}
                                                loading={opening_KBZ_Web}
                                                onClick={() => this.goToKpayApplication()}
                                            >
                                                Confirm Payment
                                            </NJVButton>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>

        )
    }
}
export default compose(withRouter)(KBZRefererPage)