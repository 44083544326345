import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Form, Image, Pagination, Row, Skeleton } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import NoData from '../../asset/no-data-found.png'
import { ModalTitle, NJVButton, NJVCard, NJVInput, NJVSelect } from '../../components/core-component'
import { SvgJobLocation, SvgSalaryOutlined } from '../../components/custom-svgIcon'
import FetchSearchUrl from '../../components/fetch-searchUrl'
import SearchFilter from '../../components/searchFilter'
import SearchPagination from '../../components/searchPagination'
import Theme from '../../components/theme'
import { Constant, DEPARTMENT_COLLECTION } from '../../core/constant'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

class PublicJobPost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: null,
      location: null,
      filterData: null,
      pageSize: Constant.pageSize,
      page: 0,
      departments: null
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    const { urlPage, filterData } = FetchSearchUrl()
    if (urlPage) {
      this.setState({
        filterData
      }, () => this.fetchData(urlPage))
    }
    this.fetchDivision();

    this.formRef?.current?.setFieldsValue({ ...filterData })
  }

  fetchData = (pageNumber) => {
    const { pageSize, page, filterData } = this.state;

    this.setState({
      isLoading: true
    })
    let requestParams = {
      page: pageNumber ? pageNumber - 1 : page,
      size: pageSize,
      ...filterData,
      location: null
    }
    ApiHandlerNoAuth({ url: Api.job_post_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
      .then((response) => {
        this.setState({
          data: response.content,
          page: pageNumber ? pageNumber : page,
          totalPage: response.totalElements,
        })
      }).catch(() => {

      }).finally(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  fetchDivision = async () => {
    try {
      this.setState({
        isFetchingDivision: true
      })
      const response = await ApiHandlerNoAuth({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
      if (response) {
        let townShipList = [];
        response.map(data => {
          data?.townshipDTOList.map(township => {
            townShipList.push({ ...township, division: { id: data.id, name: data.name } });
          })
        })
        this.setState({
          isFetchingDivision: false,
          townShipList
        })
      }
    } catch (error) {
    }
  }

  updateFilterData = (key, value) => {
    let { filterData } = this.state;

    filterData = {
      ...filterData,
      [key]: value
    }
    if (key === 'townshipId' && value) {
      filterData['townshipId'] = value.value
      this.setState({
        location: value.label
      })
    }
    this.setState({
      filterData
    })
  }

  handleSearch = () => {
    let { filterData, location } = this.state;

    const newUrl = SearchFilter({ ...filterData, location: filterData?.townshipId && location });
    window.history.pushState({}, '', newUrl)
    this.fetchData(1)
  }

  handlePagination = (pageNumber) => {
    const newUrl = SearchPagination(pageNumber)
    window.history.pushState({}, '', newUrl)
    this.fetchData(pageNumber)
  }

  clearFilter = () => {
    this.formRef.current.resetFields();
    this.setState({
      filterData: null,
      location: null
    }, () => {
      const currentUrl = new URL(window.location.href);
      currentUrl.search = '';
      window.history.replaceState({}, '', currentUrl);
      this.fetchData(1);
    })
  }

  filterOption = (input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  render() {
    const { data, page, pageSize, totalPage, isLoading, isFetchingDepartment, isFetchingDivision, townShipList } = this.state;

    return (
      <Row>
        <Col span={24} style={{ background: Theme.colors.white }}>
          <Row style={{ paddingBottom: 20 }}>
            <Col lg={3} md={1} xs={1} sm={1} />
            <Col lg={18} md={22} xs={22} sm={22}>
              <NJVCard cardbgcolor={Theme.colors.secondary} cardpadding="10px" style={{ marginBlock: 20 }} cardborder="true">
                <Form onFinish={this.handleSearch} ref={this.formRef}>
                  <Row gutter={[16, 16]}>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Form.Item name="position" style={{ marginBottom: 0 }}>
                        <NJVInput
                          size='large'
                          inputborder="#fff"
                          onChange={(e) => this.updateFilterData('position', e.target.value)}
                          style={{ background: '#fff' }}
                          placeholder={i18next.t('position')} />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Form.Item name="location" style={{ marginBottom: 0 }}>
                        <NJVSelect
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          selectborder="#fff"
                          allowClear={true}
                          loading={isFetchingDivision}
                          placeholder={i18next.t('select_location')}
                          size="large"
                          onChange={(e, obj) => this.updateFilterData('townshipId', obj)}
                          optionFilterProp="label"
                          options={
                            townShipList && townShipList?.map((township) => {
                              return {
                                label: township.division?.name,
                                options: [
                                  {
                                    value: township.id,
                                    divisionId: township.division.id,
                                    divisionName: township.division.name,
                                    label: township.name,
                                    type: 'townshipId',
                                  }
                                ]
                              }
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <Form.Item name="department" style={{ marginBottom: 0 }}>
                        <NJVSelect
                          selectborder="#fff"
                          onChange={(value) => this.updateFilterData('department', value)}
                          allowClear={true}
                          showSearch
                          filterOption={this.filterOption}
                          loading={isFetchingDepartment}
                          size="large" style={{ width: '100%' }}
                          placeholder={i18next.t('select_department')}
                          options={DEPARTMENT_COLLECTION} />
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={3} sm={12} xs={12}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <NJVButton size="large" type="primary"

                          style={{ borderRadius: 10, width: '100%' }}
                          htmlType="submit"
                          buttonprimary={Theme.colors.primary}>{i18next.t('search')}</NJVButton>
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={3} sm={24} xs={24}>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <NJVButton
                          type="text"
                          size="large"
                          style={{ width: '100%', textDecoration: 'underline', color: Theme.colors.primary }}
                          onClick={() => this.clearFilter()}
                        >
                          {i18next.t('clear_filter')}
                        </NJVButton>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </NJVCard>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ background: Theme.colors.ant_bg_color, paddingTop: 20, paddingBottom: 30 }}>
          <Row>
            <Col lg={3} md={1} xs={1} sm={1} />
            <Col lg={18} md={22} xs={22} sm={22}>
              {
                isLoading ? <Skeleton /> :
                  <Row gutter={[16, 16]}>
                    {
                      data?.length > 0 ? <>
                        {
                          data?.map((item, index) => {
                            return (
                              <Col lg={12} md={12} sm={24} xs={24} key={index}>
                                <Flex gap={10} vertical>
                                  <Link to={`${CustomPath.public_job_detail}/${item.id}`} state={{ scrollTop: true }} style={{ color: '#000' }}>
                                    <NJVCard
                                      title={
                                        <ModalTitle label={item.position} style={{ color: Theme.colors.primary, marginBlock: 10 }} />
                                      }>
                                      <Flex gap={10} vertical>
                                        <Flex align='center' gap={10}>
                                          <SvgJobLocation width={18} heigth={18} color={Theme.colors.gray} />
                                          <span>{item.location}</span>
                                        </Flex>
                                        <Flex align='center' gap={10}>
                                          <SvgSalaryOutlined width={18} heigth={18} color={Theme.colors.gray} />
                                          <span>{item.salary}</span>
                                        </Flex>
                                      </Flex>
                                    </NJVCard>
                                  </Link>
                                </Flex>
                              </Col>
                            )
                          })
                        }
                        <Col span={24} style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                          <Pagination
                            showSizeChanger={false}
                            current={page}
                            defaultPageSize={pageSize}
                            total={totalPage}
                            onChange={this.handlePagination}
                          />
                        </Col>
                      </> :
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Image src={NoData} alt='no data' width={120} preview={false} />
                        </Col>
                    }
                  </Row>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(PublicJobPost)