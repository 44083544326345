import { compose } from '@reduxjs/toolkit'
import { Col, Flex, Form, Image, message, Row, Typography } from 'antd'
import React, { Component } from 'react'
import Logo from '../../asset/NinjaLogo.png'
import Img from '../../asset/token-bg.jpg'
import { NJVButton, NJVInput } from '../../components/core-component'
import Theme from '../../components/theme'
import { NumberOnly } from '../../core/validator'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandlerNoAuth } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

class PublicTokenChecker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearching: false
        }
    }

    handleSearch = (values) => {
        this.setState({
            isSearching: true
        })
        const requestParams = {
            'uniqueId': values.shipperId
        }
        ApiHandlerNoAuth({ url: Api.public_token_checker, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestParams, disableShowMessage: true })
            .then(() => {
                const params = new URLSearchParams({ uniqueId: values.shipperId }).toString();
                this.props.navigate(`${CustomPath.public_token_list}?${params}`);
            }).catch((error) => {
                if (error.status === 500) {
                    message.destroy();
                    message.error(error.response.data.message)
                }
            })
            .finally(() => {
                this.setState({
                    isSearching: false
                })
            })
    }

    render() {
        return (
            <>
                <div style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    zIndex: 2,
                    background: '#fff'
                }}>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22}>
                            <Image
                                style={{ marginTop: 40 }}
                                width={140}
                                src={Logo}
                                preview={false}
                            />
                        </Col>
                    </Row>
                </div>
                <Row style={{ marginTop: 100 }}>
                    <Col lg={8} md={4} sm={1} xs={1} />
                    <Col lg={8} md={16} sm={22} xs={22}>
                        <Flex vertical gap={20} justify='center' align='center' >
                            <Image
                                width={250}
                                src={Img}
                                preview={false}
                            />
                            <Form style={{ width: '100%' }} onFinish={this.handleSearch}>
                                <Form.Item name="shipperId" rules={[{ required: true, validator: NumberOnly }]}>
                                    <NJVInput size="large" placeholder="Enter your Shipper ID here" />
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'center' }}>
                                    <NJVButton loading={this.state.isSearching} htmlType="submit" size="large" type="primary" buttonprimary={Theme.colors.primary}>Search</NJVButton>
                                </Form.Item>
                            </Form>
                            <Typography.Text style={{ fontWeight: 500, fontSize: 15, textAlign: 'center' }}>
                                သင်၏ Shipper ID ကိုထည့်သွင်းပြီး စုဆောင်းထားသော ကံစမ်းခွင့် ကူပွန်နံပါတ်များကို ကြည့်ရှုရန်။
                            </Typography.Text>
                        </Flex>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(PublicTokenChecker)